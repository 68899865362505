import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { Modal, Card, FlexView, Icon, Button } from 'components/common'
import { MultiSelect } from 'components/form'

import { FilterContext } from 'stores/FilterStore'

const FiltersModal = ({ isOpen, onOutsideClick, options, data }) => {
  const { t } = useTranslation()
  const { filters, setFilters } = useContext(FilterContext)

  const [state, setState] = useState({})
  
  const matches = useMemo(() => _.chain(data)
    .filter(item => {
      let match = true
      _.chain(state)
        .pickBy(filter => filter.length > 0)
        .each((filter, key) => {
          const itemValue = _.get(item, key)
          match = match && _.includes(filter, itemValue)
        })
        .value()
      return match
    })
    .value()
    .length
  , [data, state])

  useEffect(() => setFilters({}), [setFilters])//Resets all filters on first page load

  const onInputChange = useCallback(accessor => value => setState(currentState => ({
    ...currentState,
    [accessor]: value
  })), [])

  useEffect(() => setState(_.chain(options.filter(option => (option.accessor !== undefined)))
    .keyBy(({ accessor }) => accessor)
    .mapValues((_value, key) => _.get(filters, key, []))
    .value()
  ), [filters, options])

  const renderForm = useMemo(() => {
    const isLabelUndefined = (option) => option?.label === undefined //Checks if have the label prop since the `download` column doesn't have
    const isOptionAnArray = (item, option) => Array.isArray(item?.[option.accessor]) //Checks if that accessor is an array in the item
    const filterInvalidOption = (item, option) => !isLabelUndefined(option) && !isOptionAnArray(item, option) && option.filterModalEnabled === true

    return _.map(options.filter((option) => filterInvalidOption(data?.[0], option)), ({accessor, label, formatter}) => {
      const availableOptions = _.chain(data)
        .map(item => {
          const value = _.get(item, accessor)
          return {
            value,
            label: formatter ? formatter(value) : value
          }
        })
        .orderBy('label', 'asc')
        .filter(({ label }) => typeof label === 'string' || typeof label === 'number')
        .uniqBy('value')
        .map(({ value, label }) => ({
          value,
          label: _.toString(label)
        }))
        .value()

      return <MultiSelect
        margin="8px 0px"
        width="100%"
        key={accessor}
        label={label}
        selectedValues={state[accessor]}
        options={availableOptions}
        onChange={onInputChange(accessor)}
      />
    })
  }, [options, data, state, onInputChange])

  const onFilterClick = () => {
    setFilters(state)
    onOutsideClick()
  }

  const onClearFilters = () => {
    setFilters({})
  }

  return <Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
    <Card padding="16px" maxWidth="900px" width="80vw">
      <FlexView fontSize="subtitle" fontWeight="bold" flexDirection="row" justifyContent="space-between" alignItems="center" alignSelf="stretch">
        <span>{t('Filters')}</span>
        <FlexView fontSize="medium" backgroundColor="whitesmoke" padding="8px" borderRadius="component" color={matches ? 'secondary' : 'error'}>
          {t('stock.filters.itemsFound', { count: matches })}
        </FlexView>
        <Icon name="cross" color="error" width="24px" height="24px" onClick={onOutsideClick} />
      </FlexView>
      <FlexView alignSelf="stretch" margin="8px 0px" maxHeight="70vh" style={{ overflow: 'auto' }}>
        {renderForm}
      </FlexView>
      <FlexView flexDirection="row" justifyContent="flex-end" alignItems="center" alignSelf="stretch">
        <Button margin="8px" outline onClick={onClearFilters}>{t('Clear')}</Button>
        <Button margin="8px 0px 8px" color="white" backgroundColor="primary" height="39px" onClick={onFilterClick}>{t('Apply')}</Button>
      </FlexView>
    </Card>
  </Modal>
}

export default FiltersModal