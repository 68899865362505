import axios from 'axios'
import { addLogger } from 'utils/logger'

import { getDefaultHeader, manageError, manageResponse } from './api-util'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

const SHOULD_DECOMPRESS = false
addLogger(instance, false)

export default {
    getDynamoItems: async (params, idToken) => {
        try {
            const result = await instance.get(`dynamo/items?wid=${params?.wid}&tableName=${params?.tableName}&endUser=${params?.endUser}`, getDefaultHeader(idToken))
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)
        }
    },
    updateDynamo: async (params, idToken) => {
        try {
            const result = await instance.put('dynamo/update', params, getDefaultHeader(idToken))
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)
        }
    },
    deleteDocumentItem: async (params, idToken) => {
        try {
            const result = await instance.delete(`dynamo/deletion?tableName=${params?.tableName}&id=${params?.id}`, getDefaultHeader(idToken))
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)
        }
    },
}