import React from 'react'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import { useTranslation } from 'react-i18next'

import { FlexView, Icon } from 'components/common'

import { PERIDIOCITY } from 'utils/constants'
import { getPercent } from 'utils/helpers'

const ScoreFooter = ({ kpi }) => {
  const { t } = useTranslation()

  const getIcon = (kpi) => ((kpi.score - kpi.lastScore) > 0) ? "arrow-up" : "arrow-down"

  const getIconColor = (kpi) => ((kpi.score - kpi.lastScore) > 0) ? "success" : "error"

  const getPositiveSymbol = (kpi) => ((kpi.score - kpi.lastScore) > 0) ? '+' : ''

  return <FlexView borderRadius="8px" alignSelf="stretch" margin="8px" padding="4px 8px" backgroundColor="#f5f5f5" flexDirection='column' justifyContent="space-between" alignItems="stretch">
    <FlexView flexDirection='row' justifyContent="space-between" alignItems="stretch" flexWrap="wrap">
      <FlexView flexDirection="row" justifyContent="flex-start" alignItems="center" padding="8px" fontSize="small">
        {t('kpis.score_uc')}
      </FlexView>
      {kpi.peridiocity === PERIDIOCITY.MONTHLY && <FlexView flexDirection="row" justifyContent="flex-end" alignItems="center" padding="8px" fontSize="small">
        <span>{t('kpis.this_month_uc')}</span>
        <Icon name={getIcon(kpi)} width="14px" height="14px" margin="0px 4px 0px 8px"
          color={getIconColor(kpi)} />
        <span color={getIconColor(kpi)}>{getPositiveSymbol(kpi)} {kpi.score - kpi.lastScore}  pts</span>
      </FlexView>}
    </FlexView>
    <FlexView flexDirection="column" alignItems="stretch" padding="8px 0px 8px 8px">
      <Progress
        strokeWidth={5}
        percent={getPercent(kpi) || '0'}
        theme={{
          active: {
            symbol: <b>{kpi.score}</b>,
            trailColor: '#ddd',
            color: kpi.colorStatus
          }
        }} />
    </FlexView>
  </FlexView>
}

export default ScoreFooter