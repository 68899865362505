import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'

import apiExternalAdapters from 'api/external-adapters'
import apiReports from 'api/reports'

import { Button, FlexView, Icon, LoadingOverlay } from 'components/common'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { FEATURE } from 'utils/constants'

import FilterDisplay from 'containers/common/FilterDisplay'
import { FilterContext } from 'stores/FilterStore'
import FiltersModal from 'containers/common/FiltersModal'
import TableGrouped from 'components/common/TableGrouped'


const formatList = (list) =>
  _.chain(list)
    .orderBy('mrDate', 'desc')
    .orderBy('partNumber', 'asc')
    .value()

const MaterialRequisitionReportsPage = ({ stockKey }) => {
  
  const { t } = useTranslation()

  const { setPageSubtitle } = useContext(AppContext)
  const { idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const { filteredData: {filteredTableData }, setData } = useContext(FilterContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [stockKey, warehouses])

  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)


  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' + t('Material Requisition Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const onDownloadClick = useCallback(async (inspection) => {
    try {
      setLoading(true)
      const params = { reportId: inspection.id, reportType: FEATURE.MATERIAL_REQUISITION, isForDashboard: true, partNumber: inspection?.partNumber }
      const result = await apiExternalAdapters.getReportFromReporter(params, idToken)
      const link = document.createElement('a')
      link.href = result
      link.target = '_blank'
      link.click()
      setLoading(false)
    }
    catch(error){
      console.log(error)
      toast.error(error.message)
      setLoading(false)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true)
        const list = await apiReports.getMaterialRequisitionList(stock?.wid, idToken)
        setData(formatList(list)) 
      } catch (error) {
        setLoading(false)
        toast.error(error.message || error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [idToken, setData, stock])

  const data = useMemo(() => (
    _.chain(filteredTableData)
      .groupBy((item)=>`${item.id}`)
      .map((value) => ({
        id: value[0].id,
        name: value[0].name,
        inspectionDate: value[0].inspectionDate,
        inspectionType: value[0].inspectionType,
        erpReference: value[0].erpReference,
        children: value || []
      }))
      .orderBy('id', 'desc')
      .value()
  ), [filteredTableData])

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: t('ID'),
      label: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      Cell: ({ cell: { value, row } }) => (
        <FlexView
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          fontSize="13px"
          {...row.getToggleRowExpandedProps({
            style: {
              paddingLeft: `${row.depth * 32}px`,
              cursor: row.canExpand ? 'pointer' : 'inherit'
            },
            title: ''
          })}
        >
          {row.canExpand && <Icon name={row.isExpanded ? 'chevron-up' : 'chevron-down'} width="12px" height="12px" margin="0px 4px 0px 8px" color={ row.isExpanded ? 'white' : 'gray'}/> }
          {value === 'null' ? t('Not Found') : value}
        </FlexView>
      ),
      show: true,
      filterModalEnabled: true,
    },
    {
      accessor: 'partNumber',
      Header: t('Part Number'),
      label: t('Part Number'),
      customHeaderProps: {
        style: {
          textAlign: 'center',
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center',
        },
      },
      show: true,
      filterModalEnabled: false,
    },
    {
      accessor: 'name',
      Header: t('Name'),
      label: t('Name'),
      customCellProps: {
        style: {
          textAlign: 'center',
          minWidth: '10px',
        },
      }, 
      customHeaderProps: {
        style: {
          minWidth: '250px',
          textAlign: 'center',
        }
      },
      show: true,
      filterModalEnabled: true,
    },
    {
      accessor: 'inspectionType',
      Header: t('Inspection Type'),
      label: t('Inspection Type'),
      customHeaderProps: {
        style: {
          minWidth: '100px'
        }
      },
      show: true,
      filterModalEnabled: true,
    },
    {
      Header: t('Material Requisition Date'),
      label: t('Material Requisition Date'),
      accessor: 'inspectionDate',
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      },
      show: true,
      filterModalEnabled: true,
    },
    {
      accessor: 'erpReference',
      Header: t('MR Reference'),
      label: t('MR Reference'),
      customHeaderProps: {
        style: {
          minWidth: '200px'
        }
      },
      show: true,
      filterModalEnabled: true,
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => (
        <Button backgroundColor={!row.canExpand ? '#f5f5f5' : row.isExpanded ? '#667EEA' : 'inherit'} onClick={() => onDownloadClick(row.original)}
                alignItems="center" justifyContent="center" width="100%" margin='0' padding='0'> 
          <Icon name="download" width="24px" height="24px" color={ row.isExpanded ? 'white' : 'gray' } />
        </Button>
      ),
      show: true,
      filterModalEnabled: false,
    }
  ], [t, onDownloadClick])

  const toggleModal = () => setShowModal(currentState => !currentState)

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FilterDisplay options={columns} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
      </FlexView>
    </FlexView>
    <TableGrouped columns={columns} data={data} quantityLabel='Items'/>
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default MaterialRequisitionReportsPage