import axios from 'axios'
import { addLogger } from 'utils/logger'

import { getDefaultHeader, manageError, manageResponse } from './api-util'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

const SHOULD_DECOMPRESS = false
addLogger(instance, false)

export default {
    getCompleteStock: async (params, idToken) => {
        try {
          const response = await instance.get(`stock-status/completed-stock-items?wid=${params?.wid}&endUserId=${params?.endUserId}`, getDefaultHeader(idToken))
          return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)   
        }
    }, 
    upsertPlannedStock: async (params, idToken) => {
        try {
          const response = await instance.post('stock-status/upsert-planned-stock-bulk', params, getDefaultHeader(idToken))
          return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)   
        }
    }, 
    deletePlannedStock: async (id, idToken) => {
      try {
        const response = await instance.delete(`stock-status/planned-stock-deletion?id=${id}`, getDefaultHeader(idToken))
        return manageResponse(response, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)   
      }
    },
}