import { textToSlug } from 'utils/helpers'
import { functionalities as func} from 'utils/warehouse-config/functionalities'

export const getFunctionalityRoute = (warehouse, functionality) => {
    return `/${textToSlug(warehouse?.name)}/${textToSlug(functionality?.name)}`
}

export const getWarehouseRoute = (warehouse) => ['/' + textToSlug(warehouse?.name)]

export const formatWarehouses = (warehouseConfig) => 
warehouseConfig.map(warehouse => ({
  ...warehouse,
  wid: warehouse?.id ? warehouse?.id : '',
  title: warehouse?.name ? warehouse?.name : '',
  key: warehouse?.id ? warehouse?.id : '',
  /* length: warehouse.length_unit,
  weight: warehouse.weight_unit, */
  baseUrl: warehouse?.name ? textToSlug(warehouse?.name) : '',
  hasExcelBackup: true,
  functionalities: warehouse?.functionalities.filter(p=> p !== null).map(item => ({...item, icon: iconsByFunctionality[item?.id]})),
  endpoints: warehouse?.functionalities.filter(p=> p !== null).map(fun => textToSlug(fun.name))
}))

const icons = {
  'BOX': 'box',
  'FOLDER': 'folder',
  'SEARCH': 'search',
  'DOCUMENT': 'document',
  'MESSAGE-OPEN': 'message-open',
  'SHIP': 'ship',
  'MR-STATUS': 'mr-status',
  'PIPE-AGE': 'pipe-age',
  'CHART': 'chart',
  'GEAR3': 'gear-3', 
  'TIME': 'time'
}

const iconsByFunctionality = {
  [func.ARCHIVED_REPORTS.id]: icons.SHIP,
  [func.BACKLOAD_MANIFEST.id]: icons.DOCUMENT,
  [func.BLOCKED_STOCK.id]: icons['MESSAGE-OPEN'],
  [func.COLLABORATIVE_PLANNING.id]: icons.FOLDER,
  [func.DELIVERY_NOTES.id]: icons['MESSAGE-OPEN'],
  [func.KPIS.id]: icons.CHART,
  [func.MATERIAL_REQUISITION_REPORTS.id]: icons.SHIP,
  [func.MATERIAL_REQUISITION_STATUS.id]: icons['MR-STATUS'],
  [func.MODIFIED_PRODUCT_INSPECTION.id]: icons.SHIP,
  [func.PERIODICAL_REPORTS.id]: icons.SEARCH,
  [func.PIPE_AGE_REPORTS.id]: icons.TIME,
  [func.PIPE_AGE_VIEW.id]: icons.TIME,
  [func.PIPE_DATA.id]: icons.BOX,
  [func.RECEIPT_NOTES.id]: icons.DOCUMENT,
  [func.ARIIVAL_REPORTS.id]: icons.SEARCH,
  [func.RETURN_REPORTS.id]: icons.SHIP,
  [func.SERVICE_STOCK.id]: icons.BOX,
  [func.TRACKING_OF_GOODS.id]: icons.FOLDER,
  [func.WAREHOUSE_CONFIG.id]: icons.GEAR3,
  [func.DISPATCH_REPORTS.id]: icons.SHIP
}