import axios from 'axios'
import { addLogger } from 'utils/logger'

import { getDefaultHeader, manageError, manageResponse } from './api-util'
import { buildReporterAdditionalPath } from 'utils/helpers'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

const SHOULD_DECOMPRESS = false
addLogger(instance, false)

export default {
    getReportFromReporter: async (params, idToken) => {
        try {
          let additionalPath = buildReporterAdditionalPath(params)
          const response = await instance.get(`external-adapters/reports?reportId=${params?.reportId}&reportType=${params?.reportType}` + additionalPath, 
                                              getDefaultHeader(idToken))
          return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)   
        }
    },
    callPipeDataProxy: async (params, idToken) => {
      try {
        const response = await instance.post(`external-adapters/pipe-data`, params, getDefaultHeader(idToken))
        return manageResponse(response, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)   
      }
    },
}