import axios from 'axios'
import { addLogger } from 'utils/logger'

import { getDefaultHeader, manageError, manageResponse } from './api-util'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

const SHOULD_DECOMPRESS = false
addLogger(instance, false)

export default {
    getServiceStockData: async (wid, idToken) => {
        try {
            const result = await instance.get(`service-stock/items?wid=${wid}`, getDefaultHeader(idToken))
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getRackDistribution: async (wid, idToken = '') => {
        try {
            const data = await instance.get(`service-stock/rack-items?wid=${wid}`, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }, 
    getDailyServiceStock: async (params = {}, idToken = '') => {
        try {
          const data = await instance.get(`service-stock/daily-items?wid=${params?.wid}&date=${params?.date}`, getDefaultHeader(idToken))
          return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)
        }
    }, 
    getRacksFromPipes: async (params = {}, idToken = '') => {
        try {
            const result = await instance.get(`service-stock/pipe-racks?wid=${params?.wid}&valids=${params?.valids}`, getDefaultHeader(idToken))
            console.log(result)
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
}