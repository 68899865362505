import React, {useContext} from 'react'
import { FlexView, Spinner } from 'components/common'
import { ResponsiveContainer, Tooltip, ComposedChart, XAxis, YAxis, Legend, Bar, CartesianGrid } from 'recharts'
import { useTranslation } from 'react-i18next'

import { PipeAgeContext } from 'stores/PipeAgeStore'

import { CONDITIONS } from 'utils/constants'

const PipeAgeChart = ({ isLoading, modal, onInfoClick, chartData, ...rest }) => {
  const { t } = useTranslation()
  const COLORS= ['#72aeff','#b19dff','#ff9661','#ff87ab','#f18ee1']
  const { paramFilter, setIsToFilter, setParamFilter, setFilteredData, data, setCondition, condition } = useContext(PipeAgeContext)

  const onFilter = (name, conditionValue) => {
    if(paramFilter === ""){
      setFilteredData(data)
      setIsToFilter(true)
      setCondition(conditionValue)
      setParamFilter(name)
    }
    else {
      if(paramFilter === name){
        setFilteredData(data)
        setIsToFilter(false)
        setParamFilter('')
      }
      else {
        setFilteredData(data)
        setIsToFilter(true)
        setCondition(conditionValue)
        setParamFilter(name)
      }
    }
  }

  const highlightFirstBar = () => condition === CONDITIONS.lessThanSixMonths.id && paramFilter !== ""
  const highlightSecondBar = () => condition === CONDITIONS.betweenSixAndEightMonths.id && paramFilter !== ""
  const highlightThirdBar = () => condition === CONDITIONS.betweenEightAndTwelveMonths.id && paramFilter !== ""
  const highlightFourthBar = () => condition === CONDITIONS.betweenTwelveAndEighteenMonths.id && paramFilter !== ""
  const highlightFifthBar = () => condition === CONDITIONS.moreThanEighteen.id && paramFilter !== ""

  return <FlexView {...rest}>
    <FlexView flexDirection="row" justifyContent="center" alignItems="center" margin="0px 0px 16px" alignSelf="stretch">
      <FlexView fontSize="big" fontWeight="bold" justifyContent="center" alignItems="center"> { t('Pipe Age Chart Title') } </FlexView>
    </FlexView>
    {isLoading ?
      <FlexView justifyContent="center" alignItems="center" width="100%" height="100%">
        <Spinner />
      </FlexView>
      :
      <ResponsiveContainer width="99%" height={300}>
        <ComposedChart data={chartData} margin={{ top: 8 }}>
          <XAxis dataKey="name" tick={{fontSize: 15}} />
          <YAxis tick={{fontSize: 12}} />
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <Tooltip />
          <Legend tick={{fontSize: 10}}/>
          <Bar dataKey="less_than_six_months" name={t('Less Than Six Months')} barSize={20} fill={COLORS[0]} background={ highlightFirstBar() ? { fill: '#eee'} : { fill: 'transparent' } } onClick={ (e) => onFilter(e.name, CONDITIONS.lessThanSixMonths.id)}/>
          <Bar dataKey="between_six_and_eight_months" name={t('Between Six and Eight Months')} barSize={20} fill={COLORS[1]} background={ highlightSecondBar() ? { fill: '#eee'} : { fill: 'transparent' } } onClick={ (e) => onFilter(e.name, CONDITIONS.betweenSixAndEightMonths.id)}/>
          <Bar dataKey="between_eight_and_twelve_months" name={t('Between Eight and Twelve Months')} barSize={20} fill={COLORS[2]} background={ highlightThirdBar() ? { fill: '#eee'} : { fill: 'transparent' } } onClick={ (e) => onFilter(e.name, CONDITIONS.betweenEightAndTwelveMonths.id)}/>
          <Bar dataKey="between_twelve_and_eighteen_months" name={t('Between Twelve and Eighteen Months')} barSize={20} fill={COLORS[3]} background={ highlightFourthBar() ? { fill: '#eee'} : { fill: 'transparent' } } onClick={(e) => onFilter(e.name, CONDITIONS.betweenTwelveAndEighteenMonths.id)} />
          <Bar dataKey="more_than_eighteen_months" name={t('More Than Eighteen Months')} barSize={20} fill={COLORS[4]} background={ highlightFifthBar() ? { fill: '#eee'} : { fill: 'transparent' } } onClick={(e) => onFilter(e.name, CONDITIONS.moreThanEighteen.id)} />
        </ComposedChart>
      </ResponsiveContainer>
    }
  </FlexView>
}

export default PipeAgeChart