import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import styled from 'styled-components'

import apiEndUsers from 'api/end-users'
import apiStockStatus from 'api/stock-status'
import apiDocuments from 'api/documents'

import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import TableFilterableResponsive from 'components/common/TableFilterableResponsive'

import BlockedCardContainer from 'containers/blocked-stock/BlockedCardContainer'

import { DialogModal, Select, Toggle } from 'components/form'

import StockTabs from 'containers/layout/StockTabs'
import UploadContainer from 'containers/blocked-stock/UploadContainer'

import { useWindowSize } from 'hooks/useWindowSize'

import { AppContext } from 'stores/AppStore'
import { BlockedStockContext } from 'stores/BlockedStockStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { REFERENCE_SCREEN_SIZE } from 'utils/constants'
import FilterDisplay from 'containers/common/FilterDisplay'
import FiltersModal from 'containers/common/FiltersModal'
import { FilterContext } from 'stores/FilterStore'

const PageHeader = styled(FlexView)`
   flex-direction: row;
   justify-content: flex-start; 
   align-self: stretch; 
   margin: 10px 0px 0px 15px;
   padding: 0px 10px 10px 0px;
`

const BlockedStockPage = ({ stockKey }) => {
    const { t } = useTranslation()

    const { currentUser, idToken } = useContext(UserContext)
    const roles = _.get(currentUser, 'roles.' + process.env.REACT_APP_ID, {})

    const { setPageSubtitle, endUser, setEndUser, pageLoading, setPageLoading } = useContext(AppContext)
    const { warehouses } = useContext(WarehouseContext)

    const { blockedStockData, getBlockedStockData, setWid, exportToExcel } = useContext(BlockedStockContext)

    const { filters } = useContext(FilterContext)

    const [showModal, setShowModal] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [idToDelete, setIdToDelete] = useState(null)
    const [deadSpace, setDeadSpace ] = useState(0)
    const [endUsers, setEndUsers ] = useState([])

    const { height } = useWindowSize(0)

    const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [stockKey, warehouses])

    const inputEndUsers = useMemo(() => _.orderBy(_.uniqBy(endUsers, 'dsc_client'), 'label', 'asc').map(({ id_global, dsc_client }) => ({
        value: id_global,
        label: dsc_client
    })), [endUsers])

    useEffect(() => {
        setDeadSpace(height > REFERENCE_SCREEN_SIZE ? 700 : 600)
    }, [height])
    
    useEffect(() => {
        async function fetchData(){
            try {
                if(stock){
                    setPageLoading(true)
                    const response = await apiEndUsers.getAvailableEndUsers(stock?.wid, idToken)
                    if(response){
                        setEndUsers(response.endUsers)
                    }
                    setPageLoading(false)
                }  
            }
            catch(error){
                setPageLoading(false)
                toast.error(error.message)
            }
        }
        fetchData() 
    }, [stock]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(endUser){
            getBlockedStockData()
        }
    }, [ endUser, getBlockedStockData ])
 

    useEffect(() => {
        if(inputEndUsers  && inputEndUsers.length > 0){
          if(!endUser)
            setEndUser(inputEndUsers[0].value)
        }
    }, [endUser, inputEndUsers, setEndUser])
      
    useEffect(() => {
        if (stock) {
            setWid(stock.wid)
            setPageSubtitle(stock?.title + ' - ' + t('Blocked Stock Status'))
        }
    }, [stock, setPageSubtitle, t, setEndUser, inputEndUsers, setWid])
      
    const deleteStock = useCallback( async () => {
        try {
            if(idToDelete){
                setShowModal(false)
                setPageLoading(true)
                const response = await apiStockStatus.deletePlannedStock(idToDelete, idToken)
                if(response){
                    await getBlockedStockData()
                    setPageLoading(false)
                    toast.success(t('Data removed successfully')+'!')
                }
            }
        }
        catch(error){
          toast.error(error.message)
          setPageLoading(false)
        }
    }, [getBlockedStockData, idToDelete, idToken, setPageLoading, t])

    const onDeleteClick = useCallback(async (id) =>{
        setIdToDelete(id)
        setShowModal(true)
    }, [])
    const hideModal = () => setShowModal(false)

    const columns = useMemo(() => [
        {
            accessor: 'erp_ref',
            Header: t('SAP Reference'),
            label: t('SAP Reference'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'co_purchase_order',
            Header: t('Purchase Order'),
            label: t('Purchase Order'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'co_purchase_item',
            Header: t('Purchase Item'),
            label: t('Purchase Item'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'planned_quantity',
            Header: t('Planned Stock'),
            label: t('Planned Stock'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'blocked_quantity',
            Header: t('Blocked Stock'),
            label: t('Blocked Stock'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }, 
            Cell: ({ cell: { value, row } }) => ( value > 0 ? <font> { value } </font> : <font color="red" style={{ fontWeight: 600 }}> { value } </font> ) 
        },
        {
            accessor: 'entered_quantity',
            Header: t('Entered Stock'),
            label: t('Entered Stock'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'actual_quantity',
            Header: t('Actual Stock'),
            label: t('Actual Stock'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            Header: t('Stock Value'),
            label: t('Stock Value'),
            accessor: 'stock_value' ,
            Cell: (props) => {
                const { stock_value } = props.row.original
                return stock_value / 100
            },
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            accessor: 'currency_code',
            Header: t('Currency Code'),
            label: t('Currency Code'),
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            }
        },
        {
            id: 'delete',
            Header: '',
            disableFilters: true,
            customCellProps: {
                style: {
                    textAlign: 'center'
                }
            },
            Cell: ({ cell: { row } }) => ( <Button alignItems="center" justifyContent="center" width="100%"  backgroundColor="transparent" margin="0px" padding="0px" onClick={ () => { onDeleteClick(row.original.id_planned) }} disabled={!row.original.id_planned}>
                                                <Icon name="trash" color="error" width="20px" height="20px" margin="0px 16px 0px 0px" />
                                           </Button>
            ), 
            show: true
        }
    ], [onDeleteClick, t])
    
    const plannedStockQty = useMemo(() => _.sumBy(blockedStockData, (b) => b.planned_quantity), [blockedStockData])
    const blockedStockQty = useMemo(() => _.sumBy(blockedStockData, (b) => b.blocked_quantity), [blockedStockData])
    const enteredStockQty = useMemo(() => _.sumBy(blockedStockData, (b) => b.entered_quantity), [blockedStockData])
    const blockedPercentage = useMemo(() => plannedStockQty ? ((blockedStockQty / plannedStockQty) * 100).toFixed(2) : 0, [blockedStockQty, plannedStockQty])

    const validateWhatDisplay = () => {
        if(isAdmin){
            return <>
                    <UploadContainer height={25} hasSaveButton={false} />
                    <Card alignSelf="stretch" padding="0px" margin="16px">
                        <TableFilterableResponsive columns={columns} data={filteredTableData} deadSpace={deadSpace}/>
                    </Card> 
                   </>
        }
        else {
            return <>
            <BlockedCardContainer plannedQty={plannedStockQty} blockedQty={blockedStockQty} enteredQty={enteredStockQty} blockedPercentage={blockedPercentage}> </BlockedCardContainer>
            <Card alignSelf="stretch" padding="0px" margin="16px">
                <TableFilterableResponsive columns={columns.filter(item => item.id !== 'delete')} data={filteredTableData} deadSpace={deadSpace-100}/>
            </Card>
            </>
        }
    }

    const getPlannedStockTemplate = useCallback(async () =>{
        try {
            setPageLoading(true)
            const response = await apiDocuments.getPlannedStockTemplate(idToken)
            if(response){
                const link = document.createElement('a')
                link.href = response.url
                link.target = '_blank'
                link.click()
                setPageLoading(false)
            }
        }
        catch(error){
          toast.error(error.message)
          setPageLoading(false)
        }
    }, [idToken, setPageLoading])

    const toggleFilterModal = () => setShowFilterModal(currentState => !currentState)

    const onExcelExportClick = async () => {
        setPageLoading(true)
        var columnsWithoutDelete = columns.filter(function(item) {
            return item.id !== 'delete'
        })
        await exportToExcel(columnsWithoutDelete, filteredTableData)
        setPageLoading(false)
    }

    const filteredTableData = useMemo(() => {
        const filteredData = _.filter(blockedStockData, item => {
          let match = true
          _.chain(filters)
          .pickBy(filter => filter.length > 0)
          .each((filter, key) => {
            const itemValue = _.get(item, key)
            match = match && _.includes(filter, itemValue)
          })
          .value()
          return match
        })
    
        return filteredData
    }, [blockedStockData, filters])
      
    return <FlexView flex="1" position="relative" alignSelf="stretch">
        <StockTabs stock={stock} />
        <FlexView flexDirection="row" justifyContent="space-around" alignItems="flex-end" alignSelf="stretch" padding="16px 16px 0 16px" >
            <PageHeader width="100%">
                <Select margin="0px 15px 0px 0px" value={endUser} options={inputEndUsers} onChange={setEndUser} placeHolder={endUser ? endUser : t('Select an option')} />
                { roles?.hasOwnProperty('READ_AND_WRITE') 
                    ? <Toggle textOff={t('kpis.customer_view_uc')} textOn={t('kpis.administrator_view_uc')} state={isAdmin} onChange={setIsAdmin} alternate margin="10px 15px 0px 0px"/>
                    : ''
                }   
                <FilterDisplay options={columns} onTagClick={toggleFilterModal} />
                <FlexView margin="0px 16px" flexDirection="row"  alignItems="center" justifyContent="flex-end" alignSelf="stretch" flex='1' width="100%">
                <Icon name="xls" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Export to Excel')} onClick={onExcelExportClick} />
                {
                    isAdmin
                    ? <FlexView  style={{ cursor: 'pointer', margin:"0px 5px    0px 15px"}}>     
                        <Icon name="download" width="22px" height="22px" margin="0px 8px 0px 0px" tooltip={t('Download Planned Stock Template')} onClick={getPlannedStockTemplate} />
                    </FlexView>
                    : ''
                }
                <Icon name="filter" width="28px" height="28px" tooltip={t('Filter')} onClick={toggleFilterModal}/>
                </FlexView>
            </PageHeader>
        </FlexView>
        {validateWhatDisplay()}
        <LoadingOverlay visible={ pageLoading } />
        <FiltersModal isOpen={showFilterModal} onOutsideClick={toggleFilterModal} options={columns} data={blockedStockData}/>
        <DialogModal isOpen={showModal} title={t('Confirm')}
                     text={t('confirmDeletion')} onConfirm={deleteStock} onCancel={hideModal} danger
        />
    </FlexView>
}

export default BlockedStockPage