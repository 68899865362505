import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { Modal, Card, FlexView, Button, LoadingOverlay } from 'components/common'
import { TextArea, Select } from 'components/form'

import { UserContext } from 'stores/UserStore'

import apiS3 from 'api/api-s3'
import apiDocument from 'api/documents'
import apiDynamo from 'api/dynamo'

const UploadModalCollaborative = ({ isOpen, onOutsideClick, files, onFileUpload, stock, endUsers }) => {
  const { t } = useTranslation()
  const { currentUser, idToken } = useContext(UserContext)
  const [description, setDescription] = useState('')
  const [endUser, setEndUser] = useState(null)
  const [loading, setLoading] = useState(false)

  const file = useMemo(() => files.length ? files[0] : null, [files])

  useEffect(() => { 
      if(!endUser && endUsers && endUsers.length > 0){
        setEndUser(endUsers[0].value)
      }
  }, [endUser, endUsers])

  const onUploadClick = () => {
    setLoading(true)
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = async (e) => {
      try {
        const dynamoItem = {
          'name': file.name,
          'uploadDate': new Date().toUTCString(),
          'description': description,
          'endUserId': endUser,
          'uploadedBy': currentUser.firstName, 
          'wid':  stock.wid, 
          'identifier': 'name'
        }

        const fileType = file?.name?.substring(file?.name?.lastIndexOf('.') + 1)

        const item = await apiDynamo.updateDynamo({ tableName: 'COLLABORATIVE_PLANNING_TABLE', 
                                                                item: dynamoItem, 
                                                                extension: fileType }, idToken)
        const preSignedUrl = await apiDocument.getPreSignedUrl({ 
              key: 'collaborative-planning', 
              filename: item.name, 
              urlAction: 'putObject'
        }, idToken)
        
        await apiS3.uploadFile(preSignedUrl, (e.target).result)
        toast.success(t('documents.uploadSuccessful', { name: file.name }))
        setDescription('')
        onFileUpload()
        onOutsideClick()
      }
      catch (error) {
        console.log(error)
        toast.error(error.message)
      }
      finally {
        setLoading(false)
      }
    }
  }

  const onDescriptionChange = useCallback(event => setDescription(event.target.value), [])

  return (
    <Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
      <Card width="80vw" maxWidth="700px" position="relative">
      <LoadingOverlay visible={loading} borderRadius="card" />
        <FlexView fontSize="subtitle" fontWeight="bold" alignSelf="stretch">{t('Document Upload')}</FlexView>
        <FlexView fontSize="small" color="gray" alignSelf="stretch" margin="0px 0px 8px">{_.get(file, 'name', '')}</FlexView>
        <TextArea label={t('Description')} value={description} onChange={onDescriptionChange} width="100%" rows="10" />
        { endUsers.length > 1 ? 
        <Select label={t('End User')} options={endUsers} value={endUser} onChange={setEndUser} width="100%" />: null }
        <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" alignSelf="stretch">
          <Button padding="6px 14px" onClick={onOutsideClick} outline>{t('Cancel')}</Button>
          <Button backgroundColor="primary" color="white" margin="0px 0px 0px 8px" onClick={onUploadClick} disabled={description.length === 0 || !endUser}>{t('Upload')}</Button>
        </FlexView>
      </Card>
    </Modal>
  )
}

export default UploadModalCollaborative
