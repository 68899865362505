import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Manager, Reference, Popper } from 'react-popper'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import _ from 'lodash'

import { FlexView } from 'components/common'
import StockLinkSelect from 'components/home/StockLinkSelect'

import { getFunctionalityRoute } from 'utils/warehouse-config/helpers'

const Wrapper = styled(FlexView)`
  cursor: pointer;
  position: relative;
  width: 260px;
  min-height: 130px;
  background: transparent;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  margin: 8px 8px 0px;
  box-shadow: none;
  padding: 16px;
  border: 3px solid ${({ theme }) => theme.colors.lightGray};
  transition: all 0.2s;

  & > ${FlexView}:first-child {
    margin-top: 32px;
    font-family: Arvo;
    font-size: 32px;
  }

  & > ${FlexView} {
    transition: all 0.2s;
    filter: grayscale(100%);
    cursor: pointer;
    user-select: none;
  }

  &:hover {
    & > ${FlexView} {
      filter: grayscale(0%);
    }

    box-shadow: ${({ theme }) => theme.boxShadows.high};
  }

  @media screen and (min-width: 1400px) {
    width: 280px;
  }

  @media screen and (min-width: 1800px) {
    width: 400px;

    & > ${FlexView} {
      font-size: ${({ theme }) => theme.fontSizes.subtitle};
    }
  }
`

const StockCard = ({ stock }) => {
  const node = useRef(null)
  const { t } = useTranslation()
  
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
 
  const options = _.orderBy(stock?.functionalities?.filter(item => item.id !== 'PIPE_DATA')
  .map(fun => ({
    value: getFunctionalityRoute({name: stock?.title}, fun),
    label: t(fun?.label), 
    id: fun?.id
  })), 'label', 'asc')

  const handleOutsideClick = e => {
    node && node.current && !node.current.contains(e.target) && setIsOpen(false)
  }

  const onSelect = value => {
    history.push(value)
  }

  const onCardClick = () => {
    setIsOpen(true)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [])

  return <Manager>
    <div ref={node}>
      <Reference>
        {({ ref }) => (
          <Wrapper ref={ref} onClick={onCardClick}>
            <FlexView fontWeight="bold" fontSize="big" color="darkGray">{stock?.title}</FlexView>
            <FlexView fontWeight="bold" fontSize="big" color="primary" margin="8px 0px 0px" flex="1">{stock?.description || ''}</FlexView>
          </Wrapper>
        )}
      </Reference>
      <Popper placement="right">
        {({ ref, style, placement, scheduleUpdate }) => (
          <StockLinkSelect {...{ ref, style, scheduleUpdate, placement, isOpen, options, onSelect }} />
        )}
      </Popper>
    </div>
  </Manager>
}

export default StockCard