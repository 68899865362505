import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  cursor: pointer;

  &.modal-enter {
    opacity: 0;
  }
  &.modal-enter-active {
    opacity: 1;
    transition: opacity 300ms ease;
  }
  &.modal-exit {
    opacity: 1;
  }
  &.modal-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
  }
`

const ModalContent = ({ onOutsideClick, children }) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = e => {
      onOutsideClick && wrapperRef && (wrapperRef.current === e.target) && onOutsideClick()
    }
    const handleEscPress = e => {
      wrapperRef && getComputedStyle(wrapperRef.current).opacity === "1" && e.key === 'Escape' && onOutsideClick()
    }
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('keydown', handleEscPress, false)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('keydown', handleEscPress, false)
    }
  }, [onOutsideClick, wrapperRef])

  const renderComponent = <Wrapper ref={wrapperRef}>{ children }</Wrapper>
  return ReactDOM.createPortal(renderComponent, document.body)
}

/**
 * Modal component used to render
 */
const Modal = ({ isOpen, onModalExit, ...rest}) => {
  return (
    <CSSTransition
      in={isOpen}
      timeout={300}
      classNames="modal"
      onExited={onModalExit}
      unmountOnExit
    >
      <ModalContent isOpen={isOpen} {...rest} />
    </CSSTransition>
  )
}

Modal.propTypes = {
  /**
   * Boolean to control if the modal is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * Function called after the exit animation finishes
   */
  onModalExit: PropTypes.func,
  /**
   * Function called when the user clicks on the overlay, not it's content
   */
  onOutsideClick: PropTypes.func,
}

Modal.defaultProps = {
  isOpen: false
}

export default Modal