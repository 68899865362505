import axios from 'axios'
import { addLogger } from 'utils/logger'

import { getDefaultHeader, manageError, manageResponse } from './api-util'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

const SHOULD_DECOMPRESS = false
addLogger(instance, false)

export default {
   getAvailableEndUsers: async (wid, idToken) => {
        try {
          const response = await instance.get(`end-users/available?wid=${wid}`, getDefaultHeader(idToken))
          return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)   
        }
    }
}