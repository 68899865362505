import axios from 'axios'
import { addLogger } from 'utils/logger'

import { getDefaultHeader, manageError, manageResponse } from './api-util'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

const SHOULD_DECOMPRESS = false
addLogger(instance, false)

export default {
    getKpisFromDate: async (params, idToken) => {
        try {
            const data = await instance.get(`kpis/items-by-date?wid=${params?.wid}&customerId=${params?.customerId}&kpiType=${params?.kpiType}&initDate=${params?.initDate}&endDate=${params?.endDate}`, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }, 
    getKpiHistory: async (params, idToken) => {
        try {
            const data = await instance.get(`kpis/history-items?wid=${params?.wid}&customerId=${params?.customerId}&kpiType=${params?.kpiType}&kpiId=${params?.kpiId}`, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getCurrentKpis: async (params, idToken) => {
        try {
            const data = await instance.get(`kpis/current-items?wid=${params?.wid}&customerId=${params?.customerId}&kpiType=${params?.kpiType}`, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }, 
    getAdminKpis: async (params, idToken) => {
        try {
            const data = await instance.get(`kpis/admin-items?wid=${params?.wid}&customerId=${params?.customerId}&kpiType=${params?.kpiType}`, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    upInsertKpis: async (params, idToken) => {
        try {
          const response = await instance.put('kpis/upinsert-kpi', params, getDefaultHeader(idToken))
          return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)   
        }
    },
    updateKpiYearAverage: async (params, idToken) => {
        try {
            const result = await instance.put('kpis/year-average-update', params, getDefaultHeader(idToken))
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)
        }
    }
}