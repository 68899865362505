import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { toast } from 'react-toastify'
import * as _ from 'lodash'

import apiExternalAdapters from 'api/external-adapters'
import apiReports from 'api/reports'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { FEATURE } from 'utils/constants'

import { Button, FlexView, Icon, LoadingOverlay } from 'components/common'

import StockTabs from 'containers/layout/StockTabs'
import FilterDisplay from 'containers/common/FilterDisplay'
import FiltersModal from 'containers/common/FiltersModal'
import { FilterContext } from 'stores/FilterStore'
import TableGrouped from 'components/common/TableGrouped'

const formatReports = reports => _.chain(reports)
    .orderBy('closureDate', 'desc')
    .orderBy('partNumber', 'asc')
    .value()

const BackloadManifestReportsPage = ({ stockKey }) => {
    const { t } = useTranslation()
    const { setPageSubtitle, datasets } = useContext(AppContext)
    const { endUsers, idToken } = useContext(UserContext)
    const { warehouses } = useContext(WarehouseContext)

    const { filteredData: {filteredTableData }, setData } = useContext(FilterContext)

    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)

    const stock = useMemo(() => warehouses?.find(warehouse => warehouse?.wid === stockKey), [stockKey, warehouses])
    
    useEffect(() => {
        if (stock) {
            setPageSubtitle(stock?.title + ' - ' + t('Backload Manifest Reports'))
        }
    }, [stock, setPageSubtitle, t])

    const onDownloadClick = useCallback(async inspection => {
        setLoading(true)
        try {
            setLoading(true)
            const params = { reportId: inspection.id, reportType: FEATURE.BACKLOAD_MANIFEST, partNumber: inspection.partNumber }
            const result = await apiExternalAdapters.getReportFromReporter(params, idToken )
            const link = document.createElement('a')
            link.href = result
            link.target = '_blank'
            link.click()
            setLoading(false)
        }
        catch (error) {
            toast.error(error.message)
        }
        finally {
            setLoading(false)
        }
    }, [idToken])

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            try {
                const data = await apiReports.getBackloadManifestList(stock?.wid, idToken)
                setData(formatReports(data))
            } catch (error) {
                toast.error(error.message || error)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [endUsers, datasets, idToken, stock, setData])

    const data = useMemo(() => (
        _.chain(filteredTableData)
          .groupBy((item)=>`${item.id}`)
          .map((value) => ({
            id: value[0].id,
            name: value[0].name,
            inspectionDate: value[0].inspectionDate,
            erpReference: value[0].erpReference,
            pipes: _.sumBy(value, ({pipes}) => Number(pipes)),
            nonTraceable: _.sumBy(value, ({nonTraceable}) => Number(nonTraceable)),
            children: value || []
          }))
          .orderBy('id', 'desc')
          .value()
    ), [filteredTableData])

    const columns = useMemo(() => [
        {
            accessor: 'id',
            Header: t('ID'),
            label: t('ID'),
            customerHeaderProps: {
                style: {
                    minWidth: '10px'
                }
            },
            Cell: ({ cell: { value, row } }) => (
                <FlexView
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  fontSize="13px"
                  {...row.getToggleRowExpandedProps({
                    style: {
                      paddingLeft: `${row.depth * 32}px`,
                      cursor: row.canExpand ? 'pointer' : 'inherit'
                    },
                    title: ''
                  })}
                >
                  {row.canExpand && <Icon name={row.isExpanded ? 'chevron-up' : 'chevron-down'} width="12px" height="12px" margin="0px 4px 0px 8px" color={ row.isExpanded ? 'white' : 'gray'} />}
                  {value === 'null' ? t('Not Found') : value}
                </FlexView>
              ),
              show: true,
              filterModalEnabled: true,
        },
        {
            accessor: 'partNumber',
            Header: t('Part Number'),
            label: t('Part Number'),
            customerHeaderProps: {
                style: {
                    minWidth: '5px'
                }
            },
            show: true,
            filterModalEnabled: false,
        },
        {
            accessor: 'name',
            Header: t('Name'),
            label: t('Name'),
            customerHeaderProps: {
                style: {
                    minWidth: '150px'
                }
            },
            show: true,
            filterModalEnabled: true,
        },
        {
            id: 'inspectionDate',
            Header: t('Date'),
            label: t('Date'),
            accessor: 'inspectionDate',
            customerHeaderProps: {
                style: {
                    minWidth: '150px'
                }
            },
            show: true,
            filterModalEnabled: true,
        },
        {
            accessor: 'erpReference',
            Header: t('MR Reference'),
            label: t('MR Reference'),
            customerHeaderProps: {
                style: {
                    minWidth: '150px'
                }
            },
            show: true,
            filterModalEnabled: true,
        },
        {
            accessor: 'nonTraceable',
            Header: t('Non Traceable'),
            label: t('Non Traceable'),
            customerHeaderProps: {
                style: {
                    minWidth: '60px'
                }
            },
            show: true,
            filterModalEnabled: true,
        },
        {
            accessor: 'pipes',
            Header: t('Pipes'),
            label: t('Pipes'),
            customerHeaderProps: {
                style: {
                    minWidth: '60px'
                }
            },
            show: true,
            filterModalEnabled: true,
        },
        {
            accessor: 'download',
            Header: t('Download'),
            customerHeaderProps: {
                style: {
                    minWidth: '32px'
                }
            },
            Cell: ({ cell: { row } }) => (
                <Button backgroundColor={!row.canExpand ? '#f5f5f5' : row.isExpanded ? '#667EEA' : 'inherit'} onClick={() => onDownloadClick(row.original)}
                alignItems="center" justifyContent="center" width="100%" margin='0' padding='0'> 
                  <Icon name="download" width="24px" height="24px" color={ row.isExpanded ? 'white' : 'gray' } />
                </Button>
            ),
            show: true,
            filterModalEnabled: false,
        },
    ], [t, onDownloadClick])

    const toggleModal = () => setShowModal(currentState => !currentState)

    return <FlexView flex='1' position='relative' alignSelf='stretch'>
        <StockTabs stock={stock} />
        <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
        <FilterDisplay options={columns} onTagClick={toggleModal} />
        <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
            <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
        </FlexView>
        </FlexView>
        <TableGrouped columns={columns} data={data} quantityLabel='Items'/>
        <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
        <LoadingOverlay visible={loading} />
    </FlexView>
}

export default BackloadManifestReportsPage