import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'

import { Card } from 'components/common'

import TabButtons from 'containers/layout/TabButtons'

import { stockTabsLayout } from 'utils/stock-tabs-config'
import { getFunctionalityRoute } from 'utils/warehouse-config/helpers'

const Wrapper = styled(Card)`
  flex-direction: row;  
  display: flex;
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.component};
  flex-wrap: wrap;
  box-shadow: ${({ theme }) => theme.boxShadows.medium};
  padding: 0px;
  margin: 16px 16px 0px;
  overflow: hidden;
`

const StockTabs = ({ stock }) => {

  const { t } = useTranslation()
  const history = useHistory()

  const redirect = useCallback(path => () => {
    history.push(path)
  }, [history])

 

  // TODO: move these two next objects and improve these iterations
  const filteredStockTabsLayout = stockTabsLayout
    .filter(tabItem => (stock?.functionalitiesIds.includes(tabItem.id) || tabItem?.isCategory))
    .map(tabItem => {
      const allowedSubItems = tabItem?.subItems
      ?.filter(subItem => stock?.functionalitiesIds.includes(subItem?.id))
      .map(item => ({...item, label: t(item.label) }))
      return {
        ...tabItem,
        subItems: allowedSubItems
      }
    })
    .filter(tabItem => !(tabItem?.isCategory && tabItem?.subItems?.length === 0)) // Remove category if there are no subItems available

  const stockTabsOptions = filteredStockTabsLayout.map((item, index) => ({
    ...item,
    label: t(item.label),
    uri: getFunctionalityRoute({ name: stock?.title }, { name: item.label }),
    onClick: redirect(getFunctionalityRoute({ name: stock?.title }, { name: item.label })),
    subItems: item?.subItems.map(subItem => ({
        ...subItem,
        value: getFunctionalityRoute({name: stock?.title }, { name: subItem?.label }),
        uri: getFunctionalityRoute({name: stock?.title }, { name: subItem?.label }),
        onClick: redirect(getFunctionalityRoute({name: stock?.title }, { name: subItem?.label })),
      }))
  }))
                            
  const content =
    <Wrapper>
      {
        stockTabsOptions.map((option, i) =>
          <TabButtons key={i} stock={stock} option={option} index={i}></TabButtons>
        )
      }
    </Wrapper>

  return content
}

export default StockTabs