import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import moment from 'moment'

import apiReports from 'api/reports'

import { FlexView, Button, Modal, Card, LoadingOverlay, Icon } from 'components/common'
import { DatePicker, Select} from 'components/form'

import { ExportContext } from 'stores/ExportStore'

import { INTERNAL_REPORTS_TYPE } from 'utils/constants'

import { UserContext } from 'stores/UserStore'

const Title = styled.div`
    font-family: Roboto;
    font-size: 24px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`
const Subtitle = styled.div`
    font-family: Roboto;
    font-size: 15px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`

const CustomButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding:10px 0px;
    margin: 8px 0px;
    width: 200px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    &:active {
        position:relative;
        top:1px;
    }
`
const CloseButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding: 0px 0px;
    margin: 0px 0px;
    background-color: transparent;
    &:active {
        position:relative;
        top:1px;
    }
`
const ExportModalCard = styled(Card)`
width: 528px;
`

const Label = styled.div`
  padding-bottom: 15px;
`
const formatInspectionNames = names => names.map((item) => { return { 'label' : item, 'value' : item }})

const ExportModal = ({ title, subtitle, label, isOpen, onOutsideClick, onCancel, type, stock }) => {

  const { t } = useTranslation()
  const { idToken } = useContext(UserContext)
  const { initDate, endDate, setInitDate, setEndDate, modalLoading, setModalLoading, 
          exportInternalReports, setIsPdf } = useContext(ExportContext)
  
  const [ inspectionNames, setInspectionNames] = useState([])
  const [ selectedName, setSelectedName] = useState(undefined)

  const callExport = async () => {
    setModalLoading(true)
    await exportInternalReports(type, stock, selectedName)
    setModalLoading(false)
    onOutside()
  }

  useEffect(() => {
    try {
      async function fetchData() {
        if(type === INTERNAL_REPORTS_TYPE.CONSUMPTION){
          if (stock && isOpen && inspectionNames.length === 0) {
            setModalLoading(true)
            const data = await apiReports.getDeliveryNotesInspectionNames(stock?.wid, idToken)
            setInspectionNames(formatInspectionNames(data))
            setModalLoading(false)
          }
        }
      }
      fetchData()
    } catch (error) {
      console.log(error)
      toast.error(error.message)
      setInspectionNames([])
      setModalLoading(false)
    } 
  }, [ idToken, setModalLoading, stock, isOpen, inspectionNames, type])

  const onOutside = () => {
    setInspectionNames([])
    setSelectedName(undefined)
    setInitDate(undefined)
    setEndDate(undefined)
    setIsPdf(false)
    onOutsideClick()
  }

  const validateErrorMessage = () => {
   
    if (type === INTERNAL_REPORTS_TYPE.STOCK_LEVEL){
      if(!initDate)
        return t('None of the date fields can be empty')
      if(initDate > moment())
        return t('Future dates are invalid')
      return ''
    }
    else {
      if(!initDate || !endDate)
        return t('None of the date fields can be empty')
      if(initDate > endDate)
        return t('The end date must be greater than the start date')
      else if(initDate > moment() || endDate > moment())
        return t('Future dates are invalid')
      return ''
    }
  }

  return (
    <Modal isOpen={isOpen} onOutsideClick={ onOutside } onModalExit={onCancel}>
      <LoadingOverlay visible={modalLoading} />
      <ExportModalCard flexDirection="column" alignItems="center" justifyContent="center">
          <FlexView flexDirection='row' width="100%" justifyContent="space-between" alignItems="flex-start" margin="0px 0px 30px 0px ">
            <FlexView flexDirection='row' justifyContent="flex-start" width="90%">
              <FlexView flexDirection='column' alignItems="flex-start" justifyContent="center" >
                  <Title> <i> { title } </i>   </Title>
                  <Subtitle> { subtitle } </Subtitle>
              </FlexView>
            </FlexView>
            <FlexView flexDirection='row' justifyContent="flex-end">
              <CloseButton onClick={ onOutside }>
                <Icon name="cross" width="20px" height="20px" color="error"/>
              </CloseButton>
            </FlexView>
          </FlexView>
          <FlexView flexDirection='column' alignItems="center" justifyContent="center" width="100%">
            {
              type === INTERNAL_REPORTS_TYPE.CONSUMPTION ?
              <FlexView flexDirection="column" alignItems="center" justifyContent="center">
                  <Select width="300px" options={ inspectionNames } label={t('Select an option')} value={selectedName}
                  onChange={(value) => setSelectedName(value)}></Select>
              </FlexView>
              : ''
            }
            <Label> {label} </Label>
                {
                  type !== INTERNAL_REPORTS_TYPE.STOCK_LEVEL
                  ? <FlexView margin="8px"> 
                      <DatePicker value={initDate} onChange={setInitDate} label={t('export_modal.date_from')}></DatePicker> 
                      <DatePicker value={endDate} onChange={setEndDate} label={t('export_modal.date_to')}></DatePicker>
                    </FlexView>
                  : <FlexView margin="8px"> 
                      <DatePicker value={initDate} onChange={setInitDate} label=''></DatePicker> 
                    </FlexView>
                }
            <font size="3" color="red"> { validateErrorMessage() } </font>
            <CustomButton onClick={() => callExport() } disabled={ validateErrorMessage()}>  {t('kpis.export_uc')}  </CustomButton>
          </FlexView>
      </ExportModalCard>
    </Modal>
  )
}

export default ExportModal