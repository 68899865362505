import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'

import { FlexView, Button } from 'components/common'

import { UNIT, COLOR_STATUS_MAP } from 'utils/constants'
import { getValuePercent } from 'utils/helpers'

const ScoreContent = ({ kpi, onEnterValue, onEnterAverageValue }) => {
  const { t } = useTranslation()
  const renderValue = useMemo(() => (kpi.unit === UNIT.PERCENTAGE) 
  ? <FlexView justifyContent="center" alignItems="center">
      <Progress strokeWidth={6} percent={getValuePercent(kpi)} type="circle" theme={{
        active: {
          symbol: <b>{`${kpi.value}%`}</b>,
          trailColor: (kpi.value === '0' ? '#ddd' : kpi.colorStatus),
          color: '#ddd' 
        }
      }} /> 
      <FlexView fontSize="big" fontWeight="bold" color={COLOR_STATUS_MAP[kpi.yearAverageColor] || COLOR_STATUS_MAP['DEFAULT']} 
          padding="8px 0px 0px 0px">
          <font size="4" hidden={ !kpi.yearAverage}> {t('Average to Year').toUpperCase()} : { kpi.yearAverageValue?.toFixed(2)} { t(kpi.inputUnit) } </font>
      </FlexView>
      <FlexView fontSize="big" fontWeight="bold" color={COLOR_STATUS_MAP[kpi.yearAverageInputColorStatus] || COLOR_STATUS_MAP['DEFAULT']} 
          padding="8px 0px 0px 0px">
          <font size="4" hidden={!kpi.inputYearAverage}> {t('Average to Year').toUpperCase()} : {kpi.input_year_average} { t(kpi.inputUnit) }</font>
      </FlexView>
    </FlexView>
    : <FlexView justifyContent="center" alignItems="center">
        <FlexView fontSize="big" fontWeight="bold" color={COLOR_STATUS_MAP[kpi.colorStatus] || COLOR_STATUS_MAP['DEFAULT']}>
          <h3> {kpi.formatedValue} </h3>
        </FlexView>
        <FlexView fontSize="big" fontWeight="bold" color={COLOR_STATUS_MAP[kpi.yearAverageColor] || COLOR_STATUS_MAP['DEFAULT']}>
          <font size="4" hidden={!kpi.yearAverage}> {t('Average to Year').toUpperCase()} : {kpi.yearAverageValue?.toFixed(2)} { t(kpi.inputUnit) } </font>
        </FlexView>
        <FlexView fontSize="big" fontWeight="bold" color={COLOR_STATUS_MAP[kpi.yearAverageInputColorStatus] || COLOR_STATUS_MAP['DEFAULT']} >
    <font size="4" hidden={ !kpi.inputYearAverage}> {t('Average to Year').toUpperCase()} : {kpi.input_year_average} { t(kpi.inputUnit) } </font>
        </FlexView>
    </FlexView>
   
  , [kpi, t])



  const renderButton = useMemo(() => {
    const onButtonClick = () => onEnterValue(kpi)
    const onAverageButtonClick = () => onEnterAverageValue(kpi)

    return (
      <FlexView justifyContent="center" alignItems="center">
        <Button color="white" backgroundColor="secondary" onClick={onButtonClick} >
          {t('kpis.enter_value_uc')}
        </Button>
        {
          kpi.inputYearAverage
          ? <Button color="white" backgroundColor="secondary" onClick={onAverageButtonClick} >
              {t('kpis.enter_average_value_uc')}
            </Button>
          : ''
        }
        
      </FlexView>
    )
  }, [kpi, onEnterValue, onEnterAverageValue, t])

  const getContentCard = () => onEnterValue ? renderButton : renderValue

  return <FlexView
    alignSelf="stretch"
    minHeight="150px"
    margin="0px"
    padding="8px 16px"
    fontSize="big"
    fontWeight="bold"
    justifyContent="center"
    alignItems="center"
    style={{ borderTop: '1px solid #ddd', textAlign: 'center' }}
  >
    {getContentCard()}
  </FlexView>
}

export default ScoreContent