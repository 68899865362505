import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export const exportAsExcelFile = async (json, excelFileName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
    worksheet.columns = Object.keys(json ? json[0] : []).map(key => ({header: key, key: key}));
    worksheet.addRows(json);
    await workbook.xlsx.writeBuffer().then((buffer) => {
        const data = new Blob([buffer], {type: EXCEL_TYPE});
        saveAs(data, excelFileName + EXCEL_EXTENSION);
    });
}

export const exportAsExcelFileMultiSheet = async (json, excelFileName) => {
    const workbook = new ExcelJS.Workbook();
    json.forEach((row) => {
        const worksheet = workbook.addWorksheet(row.name);
        worksheet.columns = Object.keys(row?.data ? row.data[0] : []).map(key => ({header: key, key: key}));
        worksheet.addRows(row.data);
    });
    await workbook.xlsx.writeBuffer().then((buffer) => {
        const data = new Blob([buffer], {type: EXCEL_TYPE});
        saveAs(data, excelFileName + EXCEL_EXTENSION);
    });
}