import React, { useMemo } from 'react'
import TopFivePiecesChart from 'containers/stock/TopFivePiecesChart'
import PiecesByMaterialStatusChart from 'containers/stock/PiecesByMaterialStatusChart'
import StockHistoryChart from 'containers/stock/StockHistoryChart'
import { Card, Modal, Icon } from 'components/common'
import _ from 'lodash'

const ChartInfoModal = ({ isOpen, onOutsideClick, modalType }) => {
  const modals = useMemo(() => ({
    topFive: <TopFivePiecesChart modal />,
    materialStatus: <PiecesByMaterialStatusChart modal />,
    stockHistory: <StockHistoryChart modal />
  }), [])

  const renderChart = useMemo(() => {
    return modalType ? <Card position="relative">
      <Icon name="cross" color="error" width="24px" height="24px" style={{ position: 'absolute', top: '16px', right: '16px' }} onClick={onOutsideClick} />
      {_.get(modals, modalType)}
    </Card> : null
  }, [modals, modalType, onOutsideClick])

  return <Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
    {renderChart}
  </Modal>
}

export default ChartInfoModal