import { decompressResponse } from "utils/decompress"

export const getDefaultHeader = (token) => ({
    headers: { Authorization: token }
})

const isSuccessfulResponse = (response) => response && response.status === 200
const isUploadError = (response) => response && response.data.isUploadError
const hasMessageError = (response) => response && response.data.message

export const manageResponse = (response, shouldDecompress) => {
    if (isSuccessfulResponse(response)) {
        if (shouldDecompress) {
            return decompressResponse(response.data)
        }
        return response.data.body ? response?.data?.body : response.data
    } else {
        throw new Error('Couldn\'t retrieve data')
    }
}

export const manageError = (error) => {
    console.log(error)
    if (isUploadError(error.response)) {
        throw error.response.data
    }
    else if (hasMessageError(error.response)) {
        throw new Error(error.response.data.message)
    }
    else {
        throw new Error(error)
    }
}