import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useTable, useSortBy, usePagination, useFilters, useExpanded } from 'react-table'

import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'

import { Input, Select } from 'components/form'
import { TableWrapper } from 'components/common/Table'
import { Link, FlexView, Card } from 'components/common'

const Table = styled(TableWrapper)`
  display: flex;
  flex-direction: column;

  .table-wrap {
    flex: 1;
    overflow: auto;
  }

  th input {
    font-size: ${({ theme }) => theme.fontSizes.small};
    padding: 4px;
    min-height: 0px;
  }
`

const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
  const onChange = useCallback(e => {
    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
  }, [setFilter])

  return (
    <Input
      value={filterValue || ''}
      onChange={onChange}
      placeholder="Type to Filter"
      margin="0px"
      width="100%"
    />
  )
}

const sortByDate = (rowA, rowB, columnId, desc) => {
  const valueA = _.get(rowA, `original.${columnId}`)
  const valueB = _.get(rowB, `original.${columnId}`)
  return moment(valueA).isAfter(valueB) ? 1 : -1
}

const tableOptions = {
  defaultColumn: {
    Filter: DefaultColumnFilter
  },
  getSubRows: row => row.children || [],
  paginateExpandedRows: false,
  autoResetExpanded: false,
  autoResetPage: false,
  sortTypes: {
    date: sortByDate
  }
}

const TableGrouped = ( { columns, data, quantityLabel }) => {
  const { t } = useTranslation()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      ...tableOptions
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  )

  return (
    <Card padding="0px" margin="0px 16px 16px" alignSelf="stretch">
      <Table elevation="none" width="100%" fontSize="13px">
        <div className="table-wrap">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.customHeaderProps)} className="sortable">
                      <div {...column.getSortByToggleProps({ title: '' })}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" transform='rotate(180)'>
                                <path d="M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z" fill="#777777" />
                              </svg>
                              : <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z" fill="#777777" />
                              </svg>
                            : ''}
                        </span>
                      </div>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                console.log('Row', row)
                return (
                  <tr {...row.getRowProps({ 
                      style: { 
                        backgroundColor: row?.original?.children?.length === 0 ? '#ffffff' : !row.canExpand ? '#f5f5f5' : row.isExpanded ? '#667EEA' : 'inherit',
                        color: row?.original?.children?.length === 0 ? '#000000' : row.isExpanded ? '#ffffff' : '#000000'
                      } 
                  })}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps(cell.column.customCellProps)}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <FlexView flexDirection="row" justifyContent="flex-start" alignItems="center" flex="1">
            <Input
              inline
              type="number"
              label={t('Go to page')}
              fontSize="13px"
              margin="0px"
              padding="8px 4px"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              width="180px"
            />
          </FlexView>
          <FlexView flexDirection="row" alignItems="center" justifyContent="center" fontSize="13px">
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'«'}
            </Link>{' '}
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'‹'}
            </Link>{' '}
            <FlexView flexDirection="row" margin="0px 8px" fontWeight="bold">
              {pageIndex + 1} {t('of')} {pageOptions.length}
            </FlexView>
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => nextPage()} disabled={!canNextPage}>
              {'›'}
            </Link>{' '}
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'»'}
            </Link>{' '}
          </FlexView>
          <FlexView flex="1" flexDirection="row" justifyContent="flex-end" alignItems="center">
            <FlexView flexDirection="row" alignItems="center" fontSize="small" borderRadius="component" backgroundColor="whitesmoke" padding="8px" margin="0px 16px">
              { _.sumBy(data, 'qtdPC') === undefined ? '' : <><FlexView margin="0px 4px 0px 0px" color="secondary">{_.sumBy(data, 'qtdPC')}</FlexView><span style={{ marginRight: '4px' }}>{t('Pieces')}</span><span>|</span></>}
              <FlexView margin="0px 4px 0px" color="secondary">{data.length}</FlexView>
              <span>{t(quantityLabel)}</span>
            </FlexView>
            <Select
              margin="0px"
              value={pageSize}
              options={
                [5, 10, 20, 30, 40, 50].map(pageSize => ({
                  value: pageSize,
                  label: `${t('Show')} ${pageSize}`
                }))
              }
              onChange={value => {
                setPageSize(Number(value))
              }}
            />
          </FlexView>
        </div>
      </Table>
    </Card>
  )
}

export default TableGrouped