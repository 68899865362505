import React, { useMemo} from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Card, FlexView, Icon, Table } from 'components/common'

const MaterialsModal = ({ isOpen, onOutsideClick, data, options }) => {
  const { t } = useTranslation()

  const columns = useMemo(() => [
    {
      accessor: 'erp_ref',
      Header: t('Sap Reference'),
      customHeaderProps: {
        style: {
          minWidth: '40px',
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      accessor: 'dsc_material',
      Header: t('Material Description'),
      customHeaderProps: {
        style: {
          minWidth: '40px',
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
  ], [t])

  return <Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
    <Card padding="16px" maxWidth="900px" width="80vw">
      <FlexView fontSize="subtitle" fontWeight="bold" flexDirection="row" justifyContent="space-between" alignItems="center" alignSelf="stretch">
        <span>{t('Materials')}</span>
        <Icon name="cross" color="error" width="24px" height="24px" onClick={onOutsideClick} />
      </FlexView>
      <FlexView padding="16px" maxWidth="750px" width="80vw">
        <Table columns={columns} data={data} width="100%" />
      </FlexView>
    </Card>
  </Modal>
}

export default MaterialsModal