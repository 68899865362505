import React, { useState, useContext } from 'react'
import { Card, FlexView, Spinner } from 'components/common'
import AdminModal from 'containers/kpis/AdminModal'
import { KpiContext } from 'stores/KpiStore'
import ScoreFooter from 'components/kpis/ScoreFooter'
import ScoreHeader from 'components/kpis/ScoreHeader'
import ScoreContent from 'components/kpis/ScoreContent'
import styled from 'styled-components'

const Grid = styled.div`
  align-self: stretch;
  display: grid;
  align-items: stretch;
  background-color: transparent;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const AdminGrid = ({ adminKpis }) => {
  const [clickedValue, setClickedValue] = useState()
  const [isAverage, setIsAverage] = useState()
  const { setValue, pageLoading, openAdminModal, setOpenAdminModal } = useContext(KpiContext)

  const enterValueClick = (kpi) => {
    setIsAverage(false)
    setClickedValue(kpi)
    setOpenAdminModal(true)
  }

  const enterAverageClick = (kpi) => {
    setIsAverage(true)
    setClickedValue(kpi)
    setOpenAdminModal(true)
  }

  const onCancel = () => {
    setOpenAdminModal(false)
  }

  const onOutsideClick = () => {
    setIsAverage(false)
    setOpenAdminModal(false)
    setValue(null)
  }

  return (
    <Grid data-cy="admin-grid" >
      {
        adminKpis && adminKpis.map((kpi, i) => (
          <Card padding="0px" margin="8px" alignItems="stretch" flexDirection="column" justifyContent="center" fontSize="medium" flex="1" key={i}>
            {pageLoading
              ? <FlexView alignItems="center" justifyContent="center" padding="16px">
                <Spinner size="32px" />
              </FlexView>
              : <>
                <ScoreHeader kpi={kpi} isAdmin={true} />
                <ScoreContent kpi={kpi} onEnterValue={enterValueClick} onEnterAverageValue={enterAverageClick}  />
                <ScoreFooter kpi={kpi} />
              </>
            }
          </Card>
        ))}
      <AdminModal onOutsideClick={onOutsideClick} onModalExit={onCancel} isOpen={openAdminModal} kpi={clickedValue} isAverage={isAverage}/>
    </Grid>
  )
}

export default AdminGrid