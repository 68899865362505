import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'

import apiExternalAdapters from 'api/external-adapters'
import apiReports from 'api/reports'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'
import TableFilterable from 'components/common/TableFilterable'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import FilterDisplay from 'containers/common/FilterDisplay'
import FiltersModal from 'containers/common/FiltersModal'

import { FilterContext } from 'stores/FilterStore'

import { FEATURE } from 'utils/constants'

const formatReports = reports => _.orderBy(reports, 'closureDate', 'desc')

const ModifiedProductsPage = ({ stockKey }) => {

  const { t } = useTranslation()
  
  const { setPageSubtitle, datasets, formatNumberByCulture 
        } = useContext(AppContext)
  const { endUsers, idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const { filteredData: {filteredTableData }, data, setData } = useContext(FilterContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])

  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' + t('Modified Products Inspection Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const downloadReport = useCallback(async report => {
    setLoading(true)
    try {
      setLoading(true)
      const params = { reportId: report.id, reportType: FEATURE.MODIFIED_PRODUCTS }
      const result = await apiExternalAdapters.getReportFromReporter(params, idToken )
      const link = document.createElement('a')
      link.href = result
      link.target = '_blank'
      link.click()
      setLoading(false)
    }
    catch (error) {
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [idToken])

  const onDownloadClick = useCallback(report => () => {
    downloadReport(report)
  }, [downloadReport])

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const data = await apiReports.getModifiedProductList({ wid: stock.wid }, idToken)
        setData(formatReports(data))
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [endUsers, datasets, idToken, stock, setData])

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: t('ID'),
      label: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      }
    },
    {
      accessor: 'name',
      Header: t('Name'),
      label: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      accessor: 'closureDate',
      label: t('Entrance Date'),
      Header: t('Entrance Date'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      label: t('MP Reference'),
      accessor: 'erReference',
      Header: t('MP Reference'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      label: t('Owner'),
      accessor: 'owner',
      Header: t('Owner'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      label: t('Inspected'),
      accessor: 'totalInspected',
      Cell: (props) => {
        const { totalInspected } = props.row.original
        return `${formatNumberByCulture(totalInspected, 0)}`
      },
      Header: t('Inspected'),
      customHeaderProps: {
        style: {
          minWidth: '70px'
        }
      }
    },
    {
      label: t('Accepted'),
      accessor: 'totalAccepted',
      Cell: (props) => {
        const { totalAccepted } = props.row.original
        return `${formatNumberByCulture(totalAccepted, 0)}`
      },
      Header: t('Accepted'),
      customHeaderProps: {
        style: {
          minWidth: '70px'
        }
      }
    },
    {
      label: t('Rejected'),
      accessor: 'totalRejected',
      Cell: (props) => {
        const { totalRejected } = props.row.original
        return `${formatNumberByCulture(totalRejected, 0)}`
      },
      Header: t('Rejected'),
      customHeaderProps: {
        style: {
          minWidth: '70px'
        }
      }
    },
    {
      label: t('To Repair'),
      accessor: 'totalToRepair',
      Cell: (props) => {
        const { totalToRepair } = props.row.original
        return `${formatNumberByCulture(totalToRepair, 0)}`
      },
      Header: t('To Repair'),
      customHeaderProps: {
        style: {
          minWidth: '75px'
        }
      }
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(row.original)} />
      </FlexView>
    }
  ], [t, onDownloadClick, formatNumberByCulture])

  const toggleModal = () => setShowModal(currentState => !currentState)

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FilterDisplay options={columns} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
      </FlexView>
    </FlexView>
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <TableFilterable columns={columns} data={filteredTableData} />
    </Card>
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default ModifiedProductsPage