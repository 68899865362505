import React, { useContext } from 'react'
import { Card, Modal, Button, LoadingOverlay, FlexView, Icon } from 'components/common'
import { DatePicker } from 'components/form'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { KpiContext } from 'stores/KpiStore'

import { TYPE } from 'utils/constants'

const CustomButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding:10px 50px;
    width: 200px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    &:active {
        position:relative;
        top:1px;
    }
`

const CloseButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding: 0px 0px;
    margin: 0px 0px;
    background-color: transparent;
    &:active {
        position:relative;
        top:1px;
    }
`
const FlexHeader = styled(FlexView)`
    margin: 0px 0px 30px 0px;
    width: 100%;
`

const ExportModalCard = styled(Card)`
width: 528px;
height: 336px;
`
const Label = styled.div`
padding-bottom: 15px;
`


const ExportModal = ({ isOpen, onOutsideClick, onCancel }) => {

  const { t } = useTranslation()

  const { initDate, endDate, setInitDate, setEndDate, setExportExcelFile, modalLoading, kpiType } = useContext(KpiContext)

  return (
    <Modal isOpen={isOpen} onOutsideClick={onOutsideClick} onModalExit={onCancel}>
      <LoadingOverlay visible={modalLoading} />
      <ExportModalCard flexDirection="column" alignItems="center" justifyContent="center">
        <FlexHeader flexDirection='row' justifyContent="space-between" alignItems="flex-start" >
          <FlexView flexDirection='row' justifyContent="flex-start">
            <font size="5"> <i> {t('kpis.export')} {kpiType === TYPE.KPI ? t('kpis.name') : t('kpis.reciprocal_kpis')} </i>   </font>
          </FlexView>
          <FlexView flexDirection='row' justifyContent="flex-end">
            <CloseButton onClick={onOutsideClick}>
              <Icon name="cross" width="20px" height="20px" color="error"/>
            </CloseButton>
          </FlexView>
        </FlexHeader>
        <Label> {t('kpis.lbl_search_period')}</Label>
        <DatePicker value={initDate} onChange={setInitDate} label={t('export_modal.date_from')}></DatePicker>
        <DatePicker value={endDate} onChange={setEndDate} label={t('export_modal.date_to')}></DatePicker>
        <CustomButton onClick={() => setExportExcelFile(true)}>  {t('kpis.export_uc')}  </CustomButton>
      </ExportModalCard>
    </Modal>
  )

}

export default ExportModal