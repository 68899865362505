import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import _ from 'lodash'
import styled from 'styled-components'

import { Button, FlexView } from 'components/common'

import { UserContext } from 'stores/UserStore'

const OptionsWrapper = styled(FlexView)`
  font-family: 'Roboto';
  display: flex;
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  opacity: ${({ open }) => open ? '1' : '0'};
  transform: ${({ popperTransform, open }) => `${popperTransform} ${open ? 'translateX(0)' : 'scale(0.9)'} !important`};
  flex-direction: column;
  margin: 8px;
  background: #FFFFFF;
  max-height: 400px;
  box-shadow: ${({ theme }) => theme.boxShadows.high };
  overflow-y: auto;
  border-radius: 8px;
  z-index: 999;
  transition: all 0.2s ease;
`

const Option = styled(Button)`
  display: flex;
  padding: 8px 16px 8px 16px;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.big};
  width: calc(100%);
  font-weight: 500;
  font-family: 'Roboto';
  user-select: none;
  min-width: 100px;
  background-color:${({ active, theme }) => active ? theme.colors.secondary : 'transparent'} ;
  color: ${({ active, theme }) => active ? theme.colors.white : theme.colors.gray};
  transition: background-color 0.2s ease;
  cursor: ${({ disabled }) => disabled ?  'not-allowed' : 'pointer'};
  margin: 0px;

  &:hover {
    background-color: ${({ active, theme }) => active? theme.colors.secondary : theme.colors.offWhite};
  }
`

const StockLinkSelect = React.forwardRef(({ style, scheduleUpdate, placement, isOpen, options, onSelect }, ref) => {

  useEffect(() => {
    scheduleUpdate()
  }, [isOpen, scheduleUpdate])

  const onOptionClick = value => () => onSelect(value)
  const history = useHistory()

  const { currentUser } = useContext(UserContext)

  const roles = _.get(currentUser, 'roles.' + process.env.REACT_APP_ID, {})
  
  return <OptionsWrapper ref={ref} style={style} data-placement={placement} open={isOpen} popperTransform={style.transform}>
    {options.map(({ id, value, label }, index) => 
    <Option key={value} value={value} onClick={onOptionClick(value)} active={ value === history.location.pathname} disabled={ !roles.hasOwnProperty('READ' || 'READ_AND_WRITE')} >
      {label}
    </Option>)}

  </OptionsWrapper>
})

export default StockLinkSelect