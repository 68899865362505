import React, { useEffect, useState } from 'react'
import { FlexView, Icon } from 'components/common'
import { PERIDIOCITY } from 'utils/constants'
import 'react-sweet-progress/lib/style.css'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { Manager, Reference, Popper } from 'react-popper'

const Tooltip = styled.div`
  position: relative;
  max-width: 300px;
  text-align: center;
  font-family: 'Roboto';
  display: flex;
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  opacity: ${({ open }) => open ? '1' : '0'};
  flex-direction: column;
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  z-index: 999;
  transition: all 0.2s ease;
  ${({ theme }) => css`
    background-color: ${theme.colors.darkGray};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.small};
    box-shadow: ${theme.boxShadows.high};
  `}

  /* Arrow Style */
  div {
    content: '';
    transform: rotate(45deg);
    background: ${({ theme }) => theme.colors.darkGray};
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: -1;
  }

  &[data-placement='bottom'] div {
    top: -4px;
  }
  &[data-placement='right'] div {
    left: -4px;
  }
  &[data-placement='left'] div {
    right: -4px;
  }
  &[data-placement='top'] div {
    bottom: -4px;
  }
`

const TooltipPopper = React.forwardRef(({ style, scheduleUpdate, placement, visibility, tooltip, arrowProps }, ref) => {
  useEffect(() => {
    scheduleUpdate()
  }, [visibility, scheduleUpdate])

  const tooltipStyle = {
    ...style,
    transform: `${style.transform} ${visibility ? 'translateX(0)' : 'scale(0.9)'}`
  }

  return <Tooltip open={visibility} tooltipPosition="bottom" ref={ref} style={tooltipStyle} data-placement={placement} data-cy="tooltip">
    {tooltip}
    <div ref={arrowProps.ref} style={arrowProps.style} />
  </Tooltip>
})

const ScoreHeader = ({ kpi, handleClickHistory, isAdmin }) => {
  const { t } = useTranslation()
  const [visibility, setVisibility] = useState(false)

  const showTooltip = () => setVisibility(true)
  const hideTooltip = () => setVisibility(false)

  return <FlexView flexDirection="row" justifyContent="space-between" alignItems="center" elevation="low" alignSelf="stretch" padding="8px 16px" borderRadius="8px 8px 0px 0px" flex="1">
    <Manager>
      <Reference>
        {({ ref }) => (
          <FlexView flexDirection="column" justifyContent="center" ref={ref} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            <span>{t(kpi.translateNameKey)}</span>
            <FlexView color="gray" fontSize="tiny">{t('Updated at')}: {moment(kpi.updated_at).format('DD/MM/YY HH:mm')}</FlexView>
          </FlexView>
        )}
      </Reference>
      <Popper
        placement="bottom"
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport'
          }
        }}
      >
        {({ ref, style, placement, scheduleUpdate, arrowProps }) => <TooltipPopper data-cy="popover" {...{ style, scheduleUpdate, placement, visibility, tooltip: t(kpi.translateDscKey), arrowProps, ref }} />}
      </Popper>
    </Manager>
    {!isAdmin && kpi.peridiocity === PERIDIOCITY.MONTHLY && <Icon name="chart" width="18px" height="18px" margin="0px 0px 0px 16px" onClick={handleClickHistory(kpi)}/>}
  </FlexView>
}

export default ScoreHeader
