import React, { useContext } from 'react'
import { Card, FlexView, Spinner } from 'components/common'
import styled from 'styled-components'
import PipeProperty from 'components/pipe-data/PipeProperty'
import { StockContext } from 'stores/StockStore'
import { useTranslation } from 'react-i18next'

const Grid = styled(FlexView)`
  display: grid;
  grid-template-columns: repeat(5, minMax(0, 5fr));
`

const ProductDescriptionCard = () => {
  const { t } = useTranslation()
  const { pipeNominalData, loading } = useContext(StockContext)
  const { pipeNominalData: isLoading } = loading

  const nominalData = pipeNominalData ? pipeNominalData[0] : []

  return <Card margin="16px" alignItems="stretch" alignSelf="stretch" padding="16px">
    <FlexView fontSize="big" fontWeight="bold" margin="0px 0px 8px">{t('Product Description')}</FlexView>
    {isLoading ? <FlexView alignSelf="stretch" alignItems="center" justifyContent="center" padding="16px">
      <Spinner size="32px" />
    </FlexView>
    :
    <Grid width="100%">
      <PipeProperty name={t('Product Family')} value={nominalData?.product_type} />
      <PipeProperty name={'OD (")'} value={nominalData?.od_nominal} />
      <PipeProperty name={`${t('Weight')} (lb/ft)`} value={nominalData?.weight_nominal} />
      <PipeProperty name={t('Grade')} value={nominalData?.grade} />
      <PipeProperty name={t('Connection')} value={nominalData?.connection} />
      <PipeProperty name={t('Nominal Length')} value={nominalData?.length_nominal} />
      <PipeProperty name={t('Drift')} value={nominalData?.drift} />
      <PipeProperty name={t('Erp Reference')} value={nominalData?.erp_ref} />
      <PipeProperty name={t('Serial Number')} value={nominalData?.serial_number} />
      {
        nominalData?.is_modified_product ?
        <PipeProperty name={t('Modified Product Description')} value={nominalData?.modified_product_desc} />
        : ''
      }
    </Grid>}
  </Card>
}

export default ProductDescriptionCard