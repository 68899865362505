export const functionalities = {
  SERVICE_STOCK: {
    id: 'SERVICE_STOCK',
    name: 'Service Stock',
    label: 'Service Stock',
  },
  TRACKING_OF_GOODS: {
    id: 'TRACKING_OF_GOODS',
    name: 'Tracking of Goods',
    label: 'Tracking of goods',
  },
  ARIIVAL_REPORTS: {
    id: 'ARRIVAL_REPORTS',
    name: 'Arrival Reports',
    label: 'Arrival Reports',
  },
  RETURN_REPORTS: {
    id: 'RETURN_REPORTS',
    name: 'Return Reports',
    label: 'Return Reports',
  },
  MODIFIED_PRODUCT_INSPECTION: {
    id: 'MODIFIED_PRODUCT_INSPECTION',
    name: 'Modified Products Inspection Reports',
    label: 'Modified Products Inspection Reports',
  },
  MATERIAL_REQUISITION_REPORTS: {
    id: 'MATERIAL_REQUISITION_REPORTS',
    name: 'Material Requisition Reports',
    label: 'Material Requisition Reports',
  },
  PERIODICAL_REPORTS: {
    id: 'PERIODICAL_REPORTS',
    name: 'Periodical Reports',
    label: 'Periodical Reports',
  },
  ARCHIVED_REPORTS: {
    id: 'ARCHIVED_REPORTS',
    name: 'Archived Reports',
    label: 'Archived Reports',
  },
  BACKLOAD_MANIFEST: {
    id: 'BACKLOAD_MANIFEST',
    name: 'Backload Manifest',
    label: 'Backload Manifest',
  },
  RECEIPT_NOTES: {
    id: 'RECEIPT_NOTES',
    name: 'Receipt Notes',
    label: 'Receipt Notes',
  },
  DELIVERY_NOTES: {
    id: 'DELIVERY_NOTES',
    name: 'Delivery Notes',
    label: 'Delivery Notes',
  },
  MATERIAL_REQUISITION_STATUS: {
    id: 'MATERIAL_REQUISITION_STATUS',
    name: 'Material Requisition Status',
    label: 'Material Requisition Status',
  },
  COLLABORATIVE_PLANNING: {
    id: 'COLLABORATIVE_PLANNING',
    name: 'Collaborative Planning',
    label: 'Collaborative Planning',
  },
  PIPE_AGE_VIEW: {
    id: 'PIPE_AGE_VIEW',
    name: 'Pipe Age View',
    label: 'Pipe Age View',
  },
  PIPE_AGE_REPORTS: {
    id: 'PIPE_AGE_REPORTS',
    name: 'Pipe Age Reports',
    label: 'Pipe Age Reports',
  },
  PIPE_DATA: {
    id: 'PIPE_DATA',
    name: 'Pipe Data',
    label: 'Pipe Data',
  },
  KPIS: {
    id: 'KPIS',
    name: 'KPIS',
    label: 'KPIS',
  },
  BLOCKED_STOCK: {
    id: 'BLOCKED_STOCK',
    name: 'Blocked Stock',
    label: 'Blocked Stock',
  },
  WAREHOUSE_CONFIG: {
    id: 'WAREHOUSE_CONFIG',
    name: 'Warehouse Config',
    label: 'Warehouse Config',
  },
  DISPATCH_REPORTS: {
    id: 'DISPATCH_REPORTS',
    name: 'Dispatch Reports',
    label: 'Dispatch Reports',
  },
}
