import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Card, FlexView, Button } from 'components/common'

const InfoModal = ({ isOpen, onOutsideClick, title, text }) => {
  const { t } = useTranslation()

  return <Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
    <Card width="600px" height="auto" flexDirection="column" alignItems="center" justifyContent="center" elevation="low">
      <FlexView flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
        <FlexView alignSelf="flex-start" fontSize="title" fontWeight="bold">{title}</FlexView>
        <Button color="white" backgroundColor="warning" fontSize="small" margin="0px" style={{ cursor: 'default' }}>{t('apps.inDev')}</Button>
      </FlexView>
      <FlexView margin="16px 0px 0px" style={{ lineHeight: 1.5 }}>
        {text}
      </FlexView>
    </Card>
  </Modal>
}

export default InfoModal