import { HUNDRED, NOT_TOTAL_FACTOR } from "./constants"

export const getFileName = (filename, reportId) => filename + reportId

export const truncateField = (values, numOfValuesToShow = 1) => {
    if (values && values.length === 1) {
        return values[0]
    }

    if (values && values[0]) {
        const valuesToShow = values.slice(0, numOfValuesToShow)
        const remainingValuesQtdy = values.length - numOfValuesToShow
        const valuesToShowText = valuesToShow.join(', ')
        return `${valuesToShowText} and ${remainingValuesQtdy} more...`
    }

    return ''
}

export const textToSlug = (text) => text && text.toLowerCase()
    .replace(/[^\w ]+/g,'')
    .replace(/ +/g,'-')

export const loadFile = url => {
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.click()
}

export const getPercent = (kpi) => {
    return (kpi.score*HUNDRED)/(kpi.maxScore+NOT_TOTAL_FACTOR)
}
  
export const getValuePercent = (kpi) => {
    return kpi.value-NOT_TOTAL_FACTOR
}

const buildReporterQueryParam = (paramName, paramValue) => (paramValue ? `&${paramName}=${paramValue}` : '')
export const buildReporterAdditionalPath = (params) => {
    const queryParams = [
      buildReporterQueryParam('isForDashboard', params?.isForDashboard),
      buildReporterQueryParam('customerOrder', params?.customerOrder),
      buildReporterQueryParam('partNumber', params?.partNumber)
    ]
    return queryParams.join('')
}