import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { Modal, Card, FlexView, Icon, Button } from 'components/common'
import { MultiSelect } from 'components/form'

import { PipeAgeContext } from 'stores/PipeAgeStore'
import { AppContext } from 'stores/AppStore'

const FiltersModal = ({ isOpen, onOutsideClick, options }) => {
  const { t } = useTranslation()
  const { filteredData, filters, setFilters } = useContext(PipeAgeContext)
  const { formatNumberByCulture } = useContext(AppContext)

  const [state, setState] = useState({})

  const matches = useMemo(() => _.chain(filteredData)
    .filter(item => {
      let match = true
      _.chain(state)
        .pickBy(filter => filter.length > 0)
        .each((filter, key) => {
          const itemValue = _.get(item, key)
          match = match && _.includes(filter, itemValue)
        })
        .value()
      return match
    })
    .sumBy(({ qtd_pc }) => Number(qtd_pc))
    .value()
  , [state, filteredData])

  useEffect(() => setState(_.chain(options)
    .keyBy(({ accessor }) => accessor)
    .mapValues((value, key) => _.get(filters, key, []))
    .value()
  ), [options, filters])

  const onInputChange = useCallback(accessor => value => setState(currentState => ({
    ...currentState,
    [accessor]: value
  })), [])

  const renderForm = useMemo(() => {
    return _.map(options, ({ accessor, label, formatter }) => {
      const availableOptions = _.chain(filteredData)
        .map(item => {
          const value = _.get(item, accessor)
          return {
            value,
            label: formatter ? formatter(value) : value
          }
        })
        .orderBy('label', 'asc')
        .filter(({ label }) => typeof label === 'string' || typeof label === 'number')
        .uniqBy('value')
        .map(({ value, label }) => ({
          value,
          label: _.toString(label)
        }))
        .value()
      return <MultiSelect
        margin="8px 0px"
        width="100%"
        key={accessor}
        label={label}
        selectedValues={state[accessor]}
        options={availableOptions}
        onChange={onInputChange(accessor)}
      />
    })
  }, [options, state, filteredData, onInputChange])

  const onFilterClick = () => {
    setFilters(state)
    onOutsideClick()
  }

  const onClearFilters = () => {
    setFilters({})
  }

  return <Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
    <Card padding="16px" maxWidth="900px" width="80vw">
      <FlexView fontSize="subtitle" fontWeight="bold" flexDirection="row" justifyContent="space-between" alignItems="center" alignSelf="stretch">
        <span>{t('Filters')}</span>
        <FlexView fontSize="medium" backgroundColor="whitesmoke" padding="8px" borderRadius="component" color={matches ? 'secondary' : 'error'}>
          {t('stock.filters.itemsFound', { count: formatNumberByCulture(matches) })}
        </FlexView>
        <Icon name="cross" color="error" width="24px" height="24px" onClick={onOutsideClick} />
      </FlexView>
      <FlexView alignSelf="stretch" margin="8px 0px" maxHeight="70vh" style={{ overflow: 'auto' }}>
        {renderForm}
      </FlexView>
      <FlexView flexDirection="row" justifyContent="flex-end" alignItems="center" alignSelf="stretch">
        <Button margin="8px" outline onClick={onClearFilters}>{t('Clear')}</Button>
        <Button margin="8px 0px 8px" color="white" backgroundColor="primary" height="39px" onClick={onFilterClick}>{t('Apply')}</Button>
      </FlexView>
    </Card>
  </Modal>
}

export default FiltersModal