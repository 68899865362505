import React from 'react'
import { useParams } from 'react-router-dom'
import LoadingOverlay from '../components/common/LoadingOverlay'
import Cookies from 'js-cookie'

const RedirectTo = () => {
  const { to } = useParams()
  if (to && to === 'reload') {
    window.location.replace(window.location.origin)
  } else {
    Cookies.remove('_ssoToken', { path: '/', domain: process.env.REACT_APP_DOMAIN })    
    window.location.replace(process.env.REACT_APP_LOGIN_URL+'/' + (to ? to : 'logout') + '?app='+process.env.REACT_APP_ID)
  }
  return <LoadingOverlay open={true} />
}

export default RedirectTo