import React, { useContext } from 'react'
import { FlexView } from 'components/common'
import Header from 'containers/layout/Header'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from 'containers/router/PrivateRoute'

import HomePage from 'pages/Home'
import StockPage from 'pages/ServiceStock'
import PipeDataPage from 'pages/PipeData'
import MaterialRequisitionReportsPage from 'pages/MaterialRequisitionReports'
import MaterialRequisitionStatusPage from 'pages/MaterialRequisitionStatus'
import ReturnReportsPage from 'pages/ReturnReports'
import DeliveryNotesPage from 'pages/DeliveryNotes'
import MillReceiptsPage from 'pages/ReceiptNotes'
import ArrivalReportsPage from 'pages/ArrivalReports'
import TrackingOfGoodsPage from 'pages/TrackingOfGoods'
import KpisPage from 'pages/Kpis'
import PeriodicalReportsPage from 'pages/PeriodicalReports'
import PipeAgePage from 'pages/PipeAge'
import CollaborativePlanningPage from 'pages/CollaborativePlanning'
import PipeAgeReportsPage from 'pages/PipeAgeReports'
import ModifiedProductsPage from 'pages/ModifiedProducts'
import ArchivedReportsPage from 'pages/ArchivedReports'
import BlockedStockPage from 'pages/BlockedStock'
import BackloadManifestReportsPage from 'pages/BackloadManifest'
import WarehouseConfigPage from 'pages/WarehouseConfig'
import DispatchReportsPage from 'pages/DispatchReports'
import RedirectTo from 'pages/RedirectTo'

import { BlockedStockProvider } from 'stores/BlockedStockStore'
import { KpiProvider } from 'stores/KpiStore'
import { PipeAgeProvider } from 'stores/PipeAgeStore'
import { StockProvider } from 'stores/StockStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { functionalities as func } from 'utils/warehouse-config/functionalities'
import { textToSlug } from 'utils/helpers'
import { getFunctionalityRoute, getWarehouseRoute } from 'utils/warehouse-config/helpers'
import { FilterProvider } from 'stores/FilterStore'


const getRoutePathObject = (warehouse, functionalities) => functionalities.map(func => ({
    slug: textToSlug(func.name),
    funId: func.id,
    completeRoute: getFunctionalityRoute(warehouse, func)
}))

const getComponent = (functionalityRoute, key, index) => {
  let render 
  // TODO: update pages to not use key and use warehouse config
  switch (functionalityRoute.funId) {
    case (func.SERVICE_STOCK.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <StockPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.PIPE_DATA.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <PipeDataPage stockKey={key} /> 
               </PrivateRoute>
      break
    case (func.TRACKING_OF_GOODS.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <TrackingOfGoodsPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.RECEIPT_NOTES.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <MillReceiptsPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.ARIIVAL_REPORTS.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <ArrivalReportsPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.PERIODICAL_REPORTS.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <PeriodicalReportsPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.DELIVERY_NOTES.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <DeliveryNotesPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.MATERIAL_REQUISITION_REPORTS.id):
        render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                    <MaterialRequisitionReportsPage stockKey={key} />
                 </PrivateRoute>
        break
    case (func.MATERIAL_REQUISITION_STATUS.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <MaterialRequisitionStatusPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.RETURN_REPORTS.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <ReturnReportsPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.MODIFIED_PRODUCT_INSPECTION.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <ModifiedProductsPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.ARCHIVED_REPORTS.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <ArchivedReportsPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.COLLABORATIVE_PLANNING.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <CollaborativePlanningPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.PIPE_AGE_VIEW.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                <PipeAgeProvider>
                    <PipeAgePage stockKey={key} />
                </PipeAgeProvider>
               </PrivateRoute>
      break
    case (func.KPIS.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                <KpiProvider>
                    <KpisPage stockKey={key} />
                </KpiProvider>
               </PrivateRoute>
      break
    case (func.BLOCKED_STOCK.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <BlockedStockProvider>
                      <BlockedStockPage stockKey={key} />
                  </BlockedStockProvider>
               </PrivateRoute>
      break
    case (func.BACKLOAD_MANIFEST.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <BackloadManifestReportsPage stockKey={key} />
               </PrivateRoute>
      break
    case (func.PIPE_AGE_REPORTS.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <PipeAgeProvider>
                    <PipeAgeReportsPage stockKey={key} />
                  </PipeAgeProvider>
                 </PrivateRoute>
      break
    case (func.DISPATCH_REPORTS.id):
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                  <DispatchReportsPage stockKey={key} />
               </PrivateRoute>
      break
    default:
      render = <PrivateRoute path={functionalityRoute.completeRoute} key={index} exact>
                <StockPage stockKey={key} />
               </PrivateRoute>
      break
  }
  return render
}

const Router = () => {

  const { warehouses } = useContext(WarehouseContext)

  const routes = warehouses.map((warehouse) => ({
    key: warehouse.id,
    warehousePath: getWarehouseRoute(warehouse),
    functionalityRoutes: getRoutePathObject(warehouse, warehouse?.functionalities) || []
  }))
  
  return (
  <FlexView height="100vh">
    <BrowserRouter>
        <Header />
          <Switch>
            <StockProvider>
             <FilterProvider>
             <Route path="/redirect/:to" exact>
                    <RedirectTo />
             </Route>
             {
                routes.map((route, index) =>{
                  return <PrivateRoute path={route.warehousePath} key={index}>
                            <Switch>
                              {
                                route.functionalityRoutes.map((rt, index) => {
                                    return getComponent(rt, route.key, index)
                                })
                              }
                              <Route>
                                <Redirect to="/" />
                              </Route>
                            </Switch>
                          </PrivateRoute>
                })
              }
              <PrivateRoute path={"/warehouse-config"} exact>
                    <WarehouseConfigPage/>
                </PrivateRoute>
              <PrivateRoute path="/" exact>
              <PrivateRoute exact path='/'>
                <HomePage />
              </PrivateRoute>
              <Route>
                <Redirect to="/" />
              </Route>
            </PrivateRoute>
            </FilterProvider>
        </StockProvider>
      </Switch>
    </BrowserRouter>
  </FlexView>
  )
}

export default Router