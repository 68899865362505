import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'

import apiExternalAdapters from 'api/external-adapters'
import apiReports from 'api/reports'

import { LoadingOverlay, FlexView, Icon, Card } from 'components/common'
import TableFilterable from 'components/common/TableFilterable'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { FEATURE } from 'utils/constants'

import FiltersModal from 'containers/common/FiltersModal'
import { FilterContext } from 'stores/FilterStore'
import FilterDisplay from 'containers/common/FilterDisplay'

const formatReports = (reports) => _.chain(reports)
  .orderBy('triggerDate', 'desc')
  .value()

const PipeAgeReportsPage = ({ stockKey }) => {
  const { t } = useTranslation()
  const { setPageSubtitle, datasets } = useContext(AppContext)
  const { idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])
  
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const { filteredData: {filteredTableData }, data, setData } = useContext(FilterContext)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' + t('Pipe Age Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const downloadReport = useCallback(async reportId => {
    try {
      setLoading(true)
      const params = { reportId: reportId, reportType: FEATURE.PIPE_AGE }
      const result = await apiExternalAdapters.getReportFromReporter(params, idToken )
      const link = document.createElement('a')
      link.href = result
      link.target = '_blank'
      link.click()
      setLoading(false)
    }
    catch (error) {
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [idToken])

  const onDownloadClick = useCallback(id => () => {
    downloadReport(id)
  }, [downloadReport])

  useEffect(() => {
    async function fetchData(){
      try {
        setLoading(true)
        const data = await apiReports.getPipeAgeReportsList(stock?.wid, idToken)
        setData(formatReports(data))
        setLoading(false)
      }
      catch (error) {
        console.log(error)
      }
      finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [datasets, idToken, setData, stock])

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: t('ID'), 
      label: t('ID')
    },
    {
      accessor: 'name',
      Header: t('Name'),
      label: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      accessor: 'filteringType',
      Header: t('Filtering Type'),
      label: t('Filtering Type'),
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      Header: t('Extraction Date'),
      label: t('Extraction Date'),
      accessor: 'triggerDate',
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '150px'
        }
      }
    },
    {
      id: 'download',
      accessor: 'id',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { value } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(value)} />
      </FlexView>
    }
  ], [t, onDownloadClick])

  const toggleModal = () => setShowModal(currentState => !currentState)

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      <FilterDisplay options={columns} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
      </FlexView>
    </FlexView>
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <TableFilterable columns={columns} data={filteredTableData} />
    </Card>
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
    <LoadingOverlay visible={loading} />
  </FlexView>
}

export default PipeAgeReportsPage