import axios from 'axios'
import { decompressResponse } from 'utils/decompress'

const SHOULD_DECOMPRESS = false 

const instanceS3 = axios.create({
  headers: { }
})

const isSuccessfulResponse = (response) =>  response && response.status === 200

const manageResponse = (response, shouldDecompress) => {
  if (isSuccessfulResponse(response)) {
    if(shouldDecompress) {
      return decompressResponse(response.data)
    }
    return response.data.body ? response?.data?.body : response.data
  } else {
    throw new Error('Couldn\'t retrieve data') 
  }
}

const requests = {
  uploadFile : async (url, params) => {
    try {
      const response = await instanceS3.put(url, params)
      return manageResponse(response, SHOULD_DECOMPRESS)
    } catch (error) {
      console.log(error)
      throw new Error(error) 
    }
  }
}
export default requests