import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import { FlexView } from '../common'
import { withMargin } from 'utils/styled-decorators'

const RadioWrapper = styled.label`
  ${({ theme, fontSize }) => {
  const fs = theme.fontSizes[fontSize] || theme.fontSizes.medium

  return css`
    width: fit-content;
    ${withMargin()}
    display: block;
    text-align: left;
    user-select: none;
    cursor: pointer;

    input {
      display: none;

      &+div {
        height: calc(${fs} + 4px);
        font-family: 'Roboto';
        font-size: ${fs};
        font-weight: normal;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        span {
          padding-left: calc(${fs} + 4px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        span:not(:empty) {
          padding-left: calc(${fs} + 12px);
        }

        &:before,
        &:after {
          content: '';
          width: calc(${fs} + 4px);
          height: calc(${fs} + 4px);
          display: block;
          border-radius: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          position: absolute;
        }

        &:before {
          background: ${theme.colors.lightGray};
          transition: background .2s ease, transform .5s cubic-bezier(.175, .885, .32, 1.4);
        }

        &:after {
          background: #fff;
          transform: scale(.8);
          transition: transform .6s cubic-bezier(.175, .885, .32, 1.1);
        }

        &:hover {
          &:before {
            transform: scale(1.14);
          }

          &:after {
            transform: scale(.8);
          }
        }
      }

      &:checked+div {
        &:before {
          transform: scale(1.04);
          background: ${theme.colors.primary};
        }

        &:after {
          transform: scale(0.4);
          transition: transform .3s ease;
        }
      }
    }
  `}
  }
`


const Radio = ({ checked, onChange, name, value, label, ...rest }) => {
  const handleHTMLEventChange = () => {
    onChange && onChange(value)
  }

  return (
    <RadioWrapper {...rest}>
      <input type="radio" name={name} checked={checked} value={value} onChange={handleHTMLEventChange} />
      <div>
        <span>{label}</span>
      </div>
    </RadioWrapper>
  )
}

const RadioGroup = ({ name, label, value, options, onChange, margin, width, fontSize, inline }) => {
  const flexLayout = inline ? {
    flexDirection: 'row',
    flexWrap: 'wrap',
  } : {}

  return (
    <FlexView fontWeight="bold" {...{ margin, width}}>
      {label}
      <FlexView {...flexLayout}>
        {_.map(options, option => (
          <Radio
            key={JSON.stringify(option.value)}
            checked={option.value === value}
            name={name}
            value={option.value}
            label={option.label}
            onChange={onChange}
            fontSize={fontSize}
            margin={inline ? '8px 16px 8px 0px' : '8px 0px'}
          />
        ))}
      </FlexView>

    </FlexView>
  )
}


RadioGroup.propTypes = {
  /**
   * The radio input group name
   */
  name: PropTypes.string.isRequired,
  /**
   * The radio group label
   */
  label: PropTypes.string,
  /**
   * Selected option value
   */
  value: PropTypes.any,
  /**
   * Array of options, where the value of each option is unique
   */
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string
  })).isRequired,
  /**
   * Function that is called when the value is changed, being passed as parameter the selected option value
   */
  onChange: PropTypes.func,
  /**
   * Defines if the radio inputs should be rendered in the same line
   */
  inline: PropTypes.bool,
  /**
   * Override CSS width property. Must be a valid CSS width value as a string
   */
  width: PropTypes.string,
  /**
   * Override CSS margin property. Must be a valid CSS margin value as a string
   */
  margin: PropTypes.string,
  /**
   * A font size key defined in the theme
   */
  fontSize: PropTypes.string,
}

RadioGroup.defaultProps = {
  onChange: null,
  width: 'fit-content',
  margin: '8px 0px',
  label: ''
}

export default RadioGroup