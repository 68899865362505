import React, { useMemo, useContext } from 'react'
import { FlexView, Spinner, Card, Icon } from 'components/common'
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from 'recharts'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { StockContext } from 'stores/StockStore'

const COLORS = ['#72aeff', '#b19dff', '#ff9661', '#ff87ab', '#f18ee1']
const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, value
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill="black" textAnchor="middle" dominantBaseline="central" fontSize="12">
      {percent >= 0.1 && <>
       <tspan x={x} y={y}>{value}</tspan>
        <tspan x={x} y={y} dy={14}>{`${(percent * 100).toFixed(0)}%`}</tspan>
      </>}
    </text>
  )
}

const CustomTooltip = (totalPieces, t) => ({ active, payload }) => active ? (
  <Card padding="8px" maxWidth="500px">
    <FlexView margin="4px" fontWeight="bold">{payload[0].name}</FlexView>
    <FlexView margin="4px" color="metalic">{`${payload[0].value} ${t('Pieces')} (${((payload[0].value/totalPieces) * 100).toFixed(2)}%)`}</FlexView>
  </Card>
) : null

const TopFivePiecesChart = ({ isLoading, modal, onInfoClick, ...rest }) => {
  const { t } = useTranslation()
  const { stockData: { tableData }} = useContext(StockContext)
  const pieces = useMemo(() => {
    const piecesByMaterial = []
    const uniqueMaterials = [...new Set(tableData.map(item => item.dscMaterial))]
    for(const material of uniqueMaterials) {
      const sumOfPieces = tableData
        .filter(item => item.dscMaterial === material)
        .map(item => item.qtdPC)
        .reduce((res, value) => parseInt(res) + parseInt((value || 0)), 0)
      piecesByMaterial.push({label: material, value: sumOfPieces})
    }
    const top5Materials = _.orderBy(piecesByMaterial, 'value', 'desc').slice(0,5)
    return top5Materials
  }, [tableData])
  const totalPieces = useMemo(() => _.sumBy(pieces, item => parseInt(item.value)), [pieces])

  return <FlexView {...rest}>
    <FlexView flexDirection="row" alignItems="center" justifyContent="space-between" margin="0px 0px 16px" alignSelf="stretch">
      <FlexView fontSize="big" fontWeight="bold">{t('charts.topFive.title')}</FlexView>
      {!modal && <Icon name="info" width="24px" height="24px" onClick={onInfoClick} />}
    </FlexView>
    {modal && <FlexView>
      {t('charts.topFive.description')}
    </FlexView>}
    {isLoading ?
      <FlexView justifyContent="center" alignItems="center" width="100%" height="100%">
        <Spinner />
      </FlexView>
      :
      <ResponsiveContainer width="99%" height={300}>
        <PieChart>
          <Pie
            data={pieces}
            labelLine={false}
            label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="value"
            nameKey="label">
            {
              pieces.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
          <Tooltip content={CustomTooltip(totalPieces, t)}/>
        </PieChart>
      </ResponsiveContainer>
    }
  </FlexView>
}

export default TopFivePiecesChart