import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTable, useSortBy, usePagination, useFilters } from 'react-table'
import { Input, Select } from 'components/form'
import { TableWrapper } from 'components/common/Table'
import { Link, FlexView, Icon, Card, Spinner, Button } from 'components/common'
import { StockContext } from 'stores/StockStore'
import styled from 'styled-components'
import { AppContext } from 'stores/AppStore'
import { toast } from 'react-toastify'

const Table = styled(TableWrapper)`
  display: flex;
  flex-direction: column;

  .table-wrap {
    flex: 1;
    overflow: auto;
  }

  th input {
    font-size: ${({ theme }) => theme.fontSizes.small};
    padding: 4px;
    min-height: 0px;
  }
`

const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter }}) => {
  const onChange = useCallback(e => {
    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
  }, [setFilter])

  return (
    <Input
      value={filterValue || ''}
      onChange={onChange}
      margin="0px"
      width="100%"
    />
  )
}

const tableOptions = {
  defaultColumn: {
    Filter: DefaultColumnFilter,
    customHeaderProps: {
      style: {
        minWidth: '80px'
      }
    },
  },
  autoResetPage: false
}

const PipeTable = () => {
  const { t } = useTranslation()
  const { lengthUnit, weightUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit } = useContext(AppContext)
  const { pipeData: data, loading, downloadCertificate } = useContext(StockContext)
  const { pipeData: isLoading } = loading

  const onDownloadClick = useCallback(path => async () => {
    try {
      await downloadCertificate(path)
    }
    catch (e) {
      toast.error(e.data || e.message)
    }
  }, [downloadCertificate])

  const columns = useMemo(() => [
    {
      Header: t('VALID'),
      accessor: 'val_id',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      },
    },
    {
      Header: t('Sales Order'),
      accessor: 'num_sales_order',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Sales Order Item'),
      accessor: 'num_sales_item',
      customHeaderProps: {
        style: {
          minWidth: '130px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Purchase Order'),
      accessor: 'co_purchase_order',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Purchase Order Item'),
      accessor: 'co_purchase_item',
      customHeaderProps: {
        style: {
          minWidth: '130px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Heat'),
      accessor: 'heat',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: `${t('Weight')}  (${weightUnit})`,
      id: 'weight',
      accessor: ({ weight_real }) => `${convertToCurrentWeightUnit(weight_real, 'kg')}`,
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: `${t('Length')} (${lengthUnit})`,
      id: 'length',
      accessor: ({ length_real }) => `${convertToCurrentLengthUnit(length_real/1000, length_real/304.8, lengthUnit)}`,
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Rack'),
      accessor: 'rack',
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Quality Certificate'),
      accessor: 'num_certificate',
      customHeaderProps: {
        style: {
          minWidth: '125px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Download Certificate'),
      accessor: 'certificate_path',
      Cell: ({ cell: { value, row }}) => <Button width="100%" 
        alignItems="center" 
        justifyContent="center" 
        padding="0px" 
        backgroundColor="transparent" 
        disabled={!row?.original?.has_certificate_file}
        onClick={onDownloadClick(value)}
      >
        <Icon name="download" width="20px" height="20px"/>
      </Button>,
      disableFilters: true
    }
  ], [t, weightUnit, lengthUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit, onDownloadClick])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      ...tableOptions
    },
    useFilters,
    useSortBy,
    usePagination
  )

  return (
    <Card alignSelf="stretch" padding="0px" margin="0px 16px 16px">
      {isLoading ? <FlexView alignSelf="stretch" alignItems="center" justifyContent="center" padding="16px">
        <Spinner size="32px" />
      </FlexView>
      : <Table elevation="none" width="100%" fontSize="13px">
        <div className="table-wrap">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.customHeaderProps)} className="sortable">
                      <div {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" transform='rotate(180)'>
                                  <path d="M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z" fill="#777777"/>
                                </svg>
                              : <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z" fill="#777777"/>
                                </svg>
                            : ''}
                        </span>
                      </div>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps(cell.column.customCellProps)}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <FlexView flexDirection="row" justifyContent="flex-start" alignItems="center" flex="1">
            <Input
              inline
              type="number"
              label={t('Go to page')}
              fontSize="13px"
              margin="0px"
              padding="8px 4px"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              width="180px"
            />
          </FlexView>
          <FlexView flexDirection="row" alignItems="center" justifyContent="center" fontSize="13px">
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'«'}
            </Link>{' '}
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'‹'}
            </Link>{' '}
            <FlexView flexDirection="row" margin="0px 8px" fontWeight="bold">
              {pageIndex + 1} {t('of')} {pageOptions.length}
            </FlexView>
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => nextPage()} disabled={!canNextPage}>
              {'›'}
            </Link>{' '}
            <Link noDecoration color="gray" fontSize="big" fontWeight="bold" padding="4px" margin="0px 4px" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'»'}
            </Link>{' '}
          </FlexView>
          <FlexView flex="1" flexDirection="row" justifyContent="flex-end" alignItems="center">
            <Select
              margin="0px"
              value={pageSize}
              options={
                [5, 10, 20, 30, 40, 50].map(pageSize => ({
                  value: pageSize,
                  label: `${t('Show')} ${pageSize}`
                }))
              }
              onChange={value => {
                setPageSize(Number(value))
              }}
            />
          </FlexView>
        </div>
      </Table>}
    </Card>
  )
}

export default PipeTable