import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from './Button'
import { withFontWeight } from 'utils/styled-decorators'

const Link = styled(Button)`
  font-family: 'Roboto';
  display: inline;
  text-decoration: none;
  background: none;
  border-radius: 0px;
  border: none;
  box-shadow: none;
  ${withFontWeight()}

  ${({ noDecoration }) => !noDecoration && `
    &:hover {
      text-decoration: underline;
    }
  `}

  &:active {
    opacity: 0.4;
  }
`

Button.propTypes = {
  /**
   * A color key defined in the theme
   */
  color: PropTypes.string,
  /**
   * Disables the button if true
   */
  disabled: PropTypes.bool,
  /**
   * If true, swap button content for a loading spinner that matches the defined color
   */
  isLoading: PropTypes.bool,
  /**
   * If true, remove the hover underline decoration
   */
  noDecoration: PropTypes.bool,
  /**
   * Override CSS width property. Must be a valid CSS width value as a string
   */
  width: PropTypes.string,
  /**
   * Override CSS height property. Must be a valid CSS height value as a string
   */
  height: PropTypes.string,
  /**
   * Override CSS margin property. Must be a valid CSS margin value as a string
   */
  margin: PropTypes.string,
  /**
   * Override CSS padding property. Must be a valid CSS padding value as a string
   */
  padding: PropTypes.string,
  /**
   * A font size key defined in the theme
   */
  fontSize: PropTypes.string,
  /**
   * Override CSS font-weight property. Must be a valid CSS font-weight value as a string
   */
  fontWeight: PropTypes.string
}

Link.defaultProps = {
  width: 'fit-content',
  color: 'primary',
  padding: '0px',
  margin: '8px 0px'
}

export default Link