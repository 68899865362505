import React, { useState } from 'react'
import { Card, FlexView, Icon } from 'components/common'
import TopFivePiecesChart from 'containers/stock/TopFivePiecesChart'
import PiecesByMaterialStatusChart from 'containers/stock/PiecesByMaterialStatusChart'
import StockHistoryChart from 'containers/stock/StockHistoryChart'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ChartInfoModal from 'components/stock/ChartInfoModal'

const StyledCard = styled(Card)`
  ${FlexView}:last-child {
    transition: max-height 0.5s, margin 0.5s;
  }
`

const ChartsCard = () => {
  const { t } = useTranslation()
  const [showCharts, setShowCharts] = useState(false)
  const [infoModal, setInfoModal] = useState(null)

  const clearModal = () => setInfoModal(null)

  const onInfoClick = modalType => () => {
    setInfoModal(modalType)
  }

  const toggleCharts = () => setShowCharts(currentState => !currentState)

  return <StyledCard alignSelf="stretch" margin="0px 16px 16px" padding="16px">
    <FlexView flexDirection="row" alignItems="center" justifyContent="space-between" alignSelf="stretch" onClick={toggleCharts} style={{ cursor: 'pointer' }}>
      <FlexView fontSize="big" fontWeight="bold" style={{ cursor: 'pointer' }}>{t('Charts')}</FlexView>
      <Icon name={showCharts ? 'chevron-up' : 'chevron-down'} width="24px" height="24px"></Icon>
    </FlexView>
    <FlexView flexDirection="row" alignItems="stretch" alignSelf="stretch" margin={`${showCharts ? '24px' : '0px'} 0px 0px`} maxHeight={showCharts ? '500px' : '0px'} style={{ overflow: 'hidden' }}>
      <TopFivePiecesChart flex="1" margin="0px 16px 0px 0px" onInfoClick={onInfoClick('topFive')} />
      <FlexView width="1px" backgroundColor="lightGray" />
      <PiecesByMaterialStatusChart flex="1" margin="0px 16px" onInfoClick={onInfoClick('materialStatus')} />
      <FlexView width="1px" backgroundColor="lightGray" />
      <StockHistoryChart flex="2" margin="0px 0px 0px 16px" onInfoClick={onInfoClick('stockHistory')} />
    </FlexView>
    <ChartInfoModal isOpen={!!infoModal} onOutsideClick={clearModal} modalType={infoModal} />
  </StyledCard>
}

export default ChartsCard