import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import Cookies from 'js-cookie'
import _ from 'lodash'
import moment from 'moment'

import apiExternalAdapters from 'api/external-adapters'
import apiReports from 'api/reports'

import { Button, FlexView, Icon, LoadingOverlay } from 'components/common'
import { DateRangePicker } from 'components/form'

import ExportModal from 'containers/common/ExportModal'
import UnitSelect from 'containers/common/UnitSelect'
import StockTabs from 'containers/layout/StockTabs'
import FiltersModal from 'containers/common/FiltersModal'
import FilterDisplay from 'containers/common/FilterDisplay'
import TableGrouped from 'components/common/TableGrouped'

import { AppContext } from 'stores/AppStore'
import { ExportContext } from 'stores/ExportStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'
import { FilterContext } from 'stores/FilterStore'

import {FEATURE, INTERNAL_REPORTS_TYPE } from 'utils/constants'

const formatReports = reports => _.chain(reports)
  .orderBy('inspectionDate', 'desc')
  .orderBy('partNumber', 'asc')
  .value()

const DeliveryNotesPage = ({ stockKey }) => {
  const { t } = useTranslation()

  const { setPageSubtitle } = useContext(AppContext)
  const { setIsPdf } = useContext(ExportContext)
  const { idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)
  const { filteredData: { filteredTableData }, setData } = useContext(FilterContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])

  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const startOfMonth = moment().subtract(1, 'months') 
  const endOfMonth   = moment().add(1, 'day').startOf('day')
  const [startValue, setStartValue] = useState(null)
  const [endValue, setEndValue] = useState(null)

  const toggleExportModal = () => setShowExportModal(currentState => !currentState)

  useEffect(() => {
    if (!startValue) {
      setStartValue(startOfMonth)
    }
    if (!endValue) {
      setEndValue(endOfMonth)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (stock) {
      Cookies.set('_currentWarehouse', stockKey)
      setPageSubtitle(stock?.title + ' - ' + t('Delivery Notes'))
    }
  }, [ stock, setPageSubtitle, t, stockKey ])

  const onDownloadClick = useCallback(async (inspection) => {
    try {
      setLoading(true)
      const params = { reportId: inspection.id, reportType: FEATURE.DELIVERY_NOTES, 
                       customerOrder: inspection?.customerOrder, partNumber: inspection?.partNumber }
      const result = await apiExternalAdapters.getReportFromReporter(params, idToken )
      const link = document.createElement('a')
      link.href = result
      link.target = '_blank'
      link.click()
      setLoading(false)
    } catch (error) {
      console.log(error)
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [idToken])

  const getDeliveryNotesList = useCallback(async (start, end) => {
    try {
      setLoading(true)
      const dnList = await apiReports.getDeliveryNotesList({ wid: stock.wid, startDate: start.format("DD/MM/YYYY"), endDate: end.format("DD/MM/YYYY")}, idToken)
      if(dnList){
        setData(formatReports(dnList))
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      toast.error(error.message)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }, [ idToken, setData, stock ])

  useEffect(() => {
    if(startValue && endValue && Object.keys(data).length === 0){
      getDeliveryNotesList(startValue, endValue)
    }
  }, [startValue, endValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const data = useMemo(() => (
    _.chain(filteredTableData)
      .groupBy((item)=>`${item.id}-${item.ownership}-${item.partNumber}`)
      .map((value) => ({
        id: value[0].id,
        name: value[0].name,
        inspectionDate: value[0].inspectionDate,
        inspectionType: value[0].inspectionType,
        erpReference: value[0].erpReference,
        ownership: value[0].ownership,
        partNumber: value[0].partNumber,
        customerOrder: value.length === 1 ? value[0].customerOrder : null,
        children: value.length > 1 ? value : []
      }))
      .orderBy('id', 'desc')
      .value()
  ), [filteredTableData])

  console.log(data)
  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: t('ID'),
      label: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      Cell: ({ cell: { value, row } }) => (
        <FlexView
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          fontSize="13px"
          {...row.getToggleRowExpandedProps({
            style: {
              paddingLeft: `${row.depth * 32}px`,
              cursor: row.canExpand ? 'pointer' : 'inherit',
            },
            title: ''
          })}
        >
          {
            row.canExpand && 
            <Icon name={row.isExpanded ? 'chevron-up' : 'chevron-down'} width="12px" height="12px" margin="0px 4px 0px 8px" color={ row.isExpanded ? 'white' : 'gray'} />
          }
          {value === 'null' ? t('Not Found') : value}
        </FlexView>
      ),
      show: true,
      filterModalEnabled: true,
    },
    {
      accessor: 'partNumber',
      Header: t('Part Number'),
      label: t('Part Number'),
      customHeaderProps: {
        style: {
          minWidth: '250px'
        }
      }, 
      show: true,
      filterModalEnabled: false,
    },
    {
      accessor: 'name',
      Header: t('Name'),
      label: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '250px'
        }
      }, 
      show: true
    },
    {
      accessor: 'inspectionType',
      Header: t('Material Requisition Type'),
      label: t('Material Requisition Type'),
      customHeaderProps: {
        style: {
          minWidth: '250px'
        }
      }, 
      show: true,
      filterModalEnabled: true,
    },
    {
      accessor: 'customerOrder',
      Header: t('Customer PO#'),
      label: t('Customer PO#'),
      customHeaderProps: {
        style: {
          minWidth: '100px'
        }
      }, 
      show: false,
      filterModalEnabled: false,
    },
    {
      accessor: 'ownership',
      Header: t('Ownership'),
      label: t('Ownership'),
      customHeaderProps: {
        style: {
          minWidth: '100px'
        }
      }, 
      show: true,
      filterModalEnabled: true,
    },
    {
      Header: t('Delivery Notes Date'),
      label: t('Delivery Notes Date'),
      accessor: 'inspectionDate',
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '160px'
        }
      }, 
      show: true,
      filterModalEnabled: true,
    },
    {
      accessor: 'erpReference',
      Header: t('MR Reference'),
      label: t('MR Reference'),
      customHeaderProps: {
        style: {
          minWidth: '200px'
        }
      }, 
      show: true,
      filterModalEnabled: true,
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => 
        <Button backgroundColor={ row?.original?.children?.length === 0 ? '#ffffff' : !row.canExpand ? '#f5f5f5' : row.isExpanded ? '#667EEA' : 'inherit'} onClick={() => onDownloadClick(row.original)} 
                alignItems="center" justifyContent="center" width="100%" margin='0' padding='0'> 
          <Icon name="download" width="24px" height="24px" color={ row?.original?.children?.length === 0 ? 'gray' : row.isExpanded ? 'white' : 'gray' } />
        </Button>,
      show: true,
      filterModalEnabled: false,
    }
  ], [t, onDownloadClick])

  const onExcelExportClick = () => {
    setShowExportModal(prev => !prev)
  }

  const onPdfExportClick = () => {
    setIsPdf(true)
    setShowExportModal(prev => !prev)
  }

  const toggleModal = () => setShowModal(currentState => !currentState)                    

  const onStartChange = (value) => {
    if(value){
      if(value && value?.isValid){
          setEndValue(null)
          setStartValue(moment(value))
      }
    }
  }

  const onEndChange = (value) => {
    if(value){
      if(value && value?.isValid){
        if(moment(value).diff(startValue, 'months') > 6){
          toast.error(t('Please select a maximum interval of 6 months'))
        }
        else {
          setEndValue(moment(value))
          getDeliveryNotesList(startValue, moment(value))
        }
      }
    }
  }

  return <FlexView flex="1" position="relative" alignSelf="stretch">
     <LoadingOverlay visible={loading} />
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch" textAlign="center">
        <DateRangePicker startValue={startValue} onStartChange={(value) => onStartChange(value) } endValue={endValue} 
        onEndChange={(value) => onEndChange(value)} margin='0' timePicker={false} noFuture={true}/>
      <FilterDisplay options={columns} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <UnitSelect />
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
        <Icon name="xls" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Export to Excel')} onClick={onExcelExportClick} />
        <Icon name="pdf" width="28px" height="28px" margin="0px 0px 0px 0px" tooltip={t('Export to PDF')} onClick={onPdfExportClick} />
      </FlexView>
    </FlexView>
    <TableGrouped columns={columns} data={data} quantityLabel='Items' />
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
    <ExportModal title={t('Extract Consumption Report')} label="" isInterval={true} isOpen={showExportModal} type={INTERNAL_REPORTS_TYPE.CONSUMPTION } stock={stock} onOutsideClick={toggleExportModal} />
  </FlexView>

}

export default DeliveryNotesPage