import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import * as _ from 'lodash'
import Cookies from 'js-cookie'

import apiWarehouse from 'api/warehouses'

import { Card, FlexView, Modal, Button, LoadingOverlay, Icon } from 'components/common'

import PropTypes from 'prop-types'

import UnitSelect from 'containers/common/UnitSelect'

import { Input, MultiSelect } from 'components/form'

import { WarehouseContext } from 'stores/WarehouseStore'
import { UserContext } from 'stores/UserStore'
import { AppContext } from 'stores/AppStore'

const WarehouseModal = ({ isOpen, onCancel, data }) => {
  const { idToken } = useContext(UserContext)
  const { functionalities, setWarehouses } = useContext(WarehouseContext)
  const { lengthUnit, weightUnit, setLengthUnit, setWeightUnit } = useContext(AppContext)

  const [selectedFunctionalities, setSelectedFunctionalities] = useState([])
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isLoading, setLoading] = useState(false)

  const { t } = useTranslation()

  const funcs = useMemo(
    () =>
      _.uniqBy(functionalities, 'id').map(({ id, label }) => ({
        value: id,
        label: label,
      })),
    [functionalities]
  )

  const insertWarehouse = async (warehouse) => {
    try {
      setLoading(true)
      const result = await apiWarehouse.createWarehouse({ warehouse }, idToken)
      if (result) {
        toast.success(t('Warehouse.Create'))
        setWarehouses([])
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const updateWarehouse = async (warehouse) => {
    try {
      setLoading(true)
      const result = await apiWarehouse.updateWarehouse({ warehouse }, idToken)
      if (result) {
        toast.success(t('Warehouse.Update'))
        setWarehouses([])
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const deleteWarehouse = async (warehouseId) => {
    try {
      setLoading(true)
      const result = await apiWarehouse.deleteWarehouse(warehouseId, idToken)
      if (result) {
        toast.success(t('Warehouse.Delete'))
        setWarehouses([])
        Cookies.remove(`_lengthUnit${warehouseId}`)
        Cookies.remove(`_weightUnit${warehouseId}`)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const onDescriptionUpdate = (description) => setDescription(description)
  const onNameUpdate = (name) => setName(name)

  const handleSave = (isActive, { id, name, description, functionalitiesIds, length_unit, weight_unit }) => {
    if (isActive) {
      updateWarehouse({ id, name, description, functionalitiesIds, length_unit, weight_unit })
    } else {
      insertWarehouse({ id, name, description, functionalitiesIds, length_unit, weight_unit })
    }
  }

  useEffect(() => {
    Cookies.set('_currentWarehouse', data.id)
    setName(data.name)
    setDescription(data.description)
    setSelectedFunctionalities(data?.functionalities?.map(({ id }) => id) || [])
  }, [data])

  useEffect(() => {
    if (isOpen) {
      Cookies.remove(`_lengthUnit${data?.id}`)
      Cookies.remove(`_weightUnit${data?.id}`)

      setLengthUnit(data?.length_unit)
      setWeightUnit(data?.weight_unit)

      Cookies.set(`_lengthUnit${data?.id}`, data?.length_unit)
      Cookies.set(`_weightUnit${data?.id}`, data?.weight_unit)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onOutsideClick={onCancel}>
      <Card width='50%' height='70%' maxHeight='600px' position='relative'>
        <LoadingOverlay visible={isLoading} borderRadius='card' />
        <FlexView flexDirection='row' width='100%' padding='0px 0px 10px'>
          <FlexView style={{ fontSize: '25px', fontWeight: 'bold' }} width='100%'>
            {t('Warehouse.WMS Reference') + `: ${data.id} ${data.name}`}
          </FlexView>
          <Icon name='cross' color='error' margin='3px 0px 0px' onClick={onCancel} />
        </FlexView>
        <Input
          width='100%'
          maxLength='50'
          value={name || ''}
          onChange={(e) => onNameUpdate(e.target.value)}
          label={t('Warehouse.Name')}
        />
        <FlexView flexDirection='row' width='100%'>
          <Input
            width='100%'
            maxLength='150'
            value={description || ''}
            onChange={(e) => onDescriptionUpdate(e.target.value)}
            label={t('Warehouse.Description')}
          />
          <FlexView width='50%'>
            <FlexView margin='10px 0 15px 15px' fontWeight='bold' justifyContent='flex-start'>{t('Default Units')}</FlexView>
            <UnitSelect />
          </FlexView>
        </FlexView>
        <FlexView height='300px' maxHeight='400px' width='100%'>
          <MultiSelect
            label={t('Warehouse.Functionalities')}
            options={funcs}
            onChange={(value) => setSelectedFunctionalities(value)}
            selectedValues={selectedFunctionalities}
            width='100%'
            height='90%'
            style={{ overflowY: 'scroll', maxHeight: '200px' }}
            ordered
          />
        </FlexView>
        <FlexView
          style={{ borderTop: '1px solid', width: '100%' }}
          flexDirection='row'
          alignSelf='stretch'
          justifyContent='flex-end'
        ></FlexView>
        <FlexView flexDirection='row' alignSelf='stretch' justifyContent='flex-end' padding='5px 0px 0px'>
          {data?.isActive ? (
            <Button margin='0px 10px' onClick={() => deleteWarehouse(data.id)} backgroundColor='error' color='white'>
              {t('Delete')}
            </Button>
          ) : null}
          <Button
            margin='0px'
            onClick={() =>
              handleSave(data?.isActive, {
                id: data?.id,
                name: name,
                description: description,
                functionalitiesIds: selectedFunctionalities,
                length_unit: lengthUnit,
                weight_unit: weightUnit,
              })
            }
            backgroundColor='secondary'
            color='white'
          >
            {t('Save')}
          </Button>
        </FlexView>
      </Card>
    </Modal>
  )
}

WarehouseModal.proptype = {
  /**
   * Boolean to control if the dialog is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * Function called when the user confirms the dialog
   */
  onConfirm: PropTypes.func,
  /**
   * Function called when the user cancel the dialog or clicks outside of it
   */
  onCancel: PropTypes.func,
}

WarehouseModal.defaultProps = {
  isOpen: false,
}

export default WarehouseModal
