import React, { useState, useContext } from 'react'
import { Card, FlexView, Spinner } from 'components/common'
import 'react-sweet-progress/lib/style.css'
import HistoryModal from 'containers/kpis/HistoryModal'
import { KpiContext } from 'stores/KpiStore'
import ScoreFooter from 'components/kpis/ScoreFooter'
import ScoreContent from 'components/kpis/ScoreContent'
import ScoreHeader from 'components/kpis/ScoreHeader'
import styled from 'styled-components'

const Grid = styled.div`
  align-self: stretch;
  display: grid;
  align-items: stretch;
  background-color: transparent;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const CustomerGrid = ({ kpis }) => {
  const { historyKpi, setHistoryKpi, pageLoading, setHistoryData } = useContext(KpiContext)
  const [openHistory, setOpenHistory] = useState(false)

  const handleClickHistory = (kpi) => () => {
    setOpenHistory(true)
    setHistoryKpi(kpi)
  }

  const onCancelHistory = () => {
    setOpenHistory(false)
  }

  const onOutsideClickHistory = () => {
    setHistoryData([])
    setOpenHistory(false)
  }

  return (
    <Grid data-cy="customer-grid" >
      {kpis && kpis.map((kpi, i) => (
        <Card padding="0px" margin="8px" alignItems="stretch" flexDirection="column" justifyContent="center" fontSize="medium" flex="1" key={i} 
        data-cy={"card"+ i} >
          {pageLoading
            ? <FlexView alignItems="center" justifyContent="center" padding="16px" >
              <Spinner size="32px" />
            </FlexView>
            : <>
              <ScoreHeader kpi={kpi} handleClickHistory={handleClickHistory} />
              <ScoreContent kpi={kpi} />
              <ScoreFooter kpi={kpi} />
            </>
          }
        </Card>
      ))}
      <HistoryModal onOutsideClick={onOutsideClickHistory} onModalExit={onCancelHistory} isOpen={openHistory} kpi={historyKpi}></HistoryModal>
    </Grid>
  )
}

export default CustomerGrid