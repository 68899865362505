import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { Card, FlexView, Icon } from 'components/common'

import WarehouseModal from 'containers/warehouse/WarehouseModal'

import { WarehouseContext } from 'stores/WarehouseStore'
import { UserContext } from 'stores/UserStore'

const WarehouseConfigPage = () => {
    const { allWarehouses } = useContext(WarehouseContext)
    const { t } = useTranslation()

    const { currentUser } = useContext(UserContext)
    const roles = _.get(currentUser, 'roles.' + process.env.REACT_APP_ID, {})

    const [isOpen, setIsOpen] = useState(false)
    const [cardData, setCardData] = useState([])  

    const getIcon = (isActive) => isActive ? 'pen-2' : 'plus'
    const getIconColor = (isActive) => isActive ? 'primary' : 'success'
    const getTooltipMessage = (isActive) => isActive ? 'Click to edit the warehouse' : 'Setup a new warehouse'
    const getActiveStyle = (isActive) => isActive
        ? { backgroundColor: 'white', color: '#303030', opacity: 1 }
        : { backgroundColor: 'rgba(255, 255, 255, .5)', color: '#c6c6c6', opacity: .7}

    const openModal = (data) => {
        setCardData(data)
        setIsOpen(true)
    }

    const closeModal = () => setIsOpen(false)

    const WarehouseCard = ({ warehouse }) => 
    <Card max-width="95%" width='325px' height='175px' elevation='high' margin="20px 20px" style={ getActiveStyle(warehouse?.isActive)}>
        <FlexView width="100%" padding="0px 10px 11px 10px" margin="0 0 5px 0" flexDirection="row" alignSelf="center" alignItems="center" justifyContent="space-between" style={{ borderBottom: '1px solid #DBDBDB' }}>
            <FlexView fontWeight="bold" fontSize="22px">{`${warehouse.name}`}</FlexView>
            <Icon name={getIcon(warehouse?.isActive)}
                width='24px'
                height='24px'
                tooltip={ roles.hasOwnProperty('READ_AND_WRITE') ? getTooltipMessage(warehouse?.isActive) : t('You need more permissions, ask system administrators') }
                color={getIconColor(warehouse.isActive)}
                onClick={() => roles.hasOwnProperty('READ_AND_WRITE') ? openModal(warehouse) : null}
                disabled={!roles.hasOwnProperty('READ_AND_WRITE')}
            />
        </FlexView>
        {warehouse?.isActive ? <FlexView margin='10px 0 0 0'>{`Warehouse Description: ${warehouse?.description}`}</FlexView> : null}
        <FlexView justifyContent='flex-start' margin='5px 0' width='100%'>{t('Warehouse.WMS Reference') + `: ${warehouse.id} -  ${warehouse.name}`}</FlexView>
        <FlexView>{t('Warehouse.Total') + `: ${warehouse?.functionalities?.length}`}</FlexView>
    </Card>

    return (
        <FlexView alignSelf='center' flexDirection='row' justifyContent='left' flexWrap="wrap">
            {allWarehouses ? allWarehouses.map(item =>
                <WarehouseCard key={item?.id} warehouse={item}></WarehouseCard>) : null}
            <WarehouseModal isOpen={isOpen} onCancel={closeModal} data={cardData} />
        </FlexView>
    )
}

export default WarehouseConfigPage