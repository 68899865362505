import axios from 'axios'
import { addLogger } from 'utils/logger'

import { getDefaultHeader, manageError, manageResponse } from './api-util'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

const SHOULD_DECOMPRESS = false
addLogger(instance, false)

export default {
    getMRFile: async (mrFileName, idToken = '') => {
        try {
          const response = await instance.get(`documents/mr-file?mrFileName=${mrFileName}`, getDefaultHeader(idToken))
          return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getPlannedStockTemplate: async (idToken) => {
      try {
          const response = await instance.get(`documents/planned-stock-template`, getDefaultHeader(idToken))
          return manageResponse(response, SHOULD_DECOMPRESS)
      } catch (error) {
          manageError(error)   
      }
    },
    getUserGuide: async (version, idToken = '') => {
        try {
          const data = await instance.get(`documents/user-guide?version=${version}`, getDefaultHeader(idToken))
          return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)
        }
    }, 
    getPreSignedUrl: async (params, idToken) => {
        try {
            const result = await instance.get(`documents/presigned-url?key=${params?.key}&filename=${params?.filename}&urlAction=${params?.urlAction}`, getDefaultHeader(idToken))
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)
        }
    }, 
    deleteFile: async (params, idToken) => {
      try {
          const result = await instance.delete(`documents/deletion?key=${encodeURIComponent(params?.key)}&filename=${encodeURIComponent(params?.filename)}`, getDefaultHeader(idToken))
          return manageResponse(result, SHOULD_DECOMPRESS)
      } catch (error) {
        manageError(error)
      }
    }, 
}