import React, { useCallback, useMemo, useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import * as _ from 'lodash'

import apiExternalAdapters from 'api/external-adapters'
import apiReports from 'api/reports'

import { Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import TableFilterable from 'components/common/TableFilterable'
import StockTabs from 'containers/layout/StockTabs'
import FilterDisplay from 'containers/common/FilterDisplay'
import FiltersModal from 'containers/common/FiltersModal'

import { WarehouseContext } from 'stores/WarehouseStore'
import { FilterContext } from 'stores/FilterStore'
import { UserContext } from 'stores/UserStore'
import { AppContext } from 'stores/AppStore'

import { FEATURE } from 'utils/constants'

const DispatchReportsPage = ({ stockKey }) => {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { t } = useTranslation()
  const { setPageSubtitle } = useContext(AppContext)
  const { warehouses } = useContext(WarehouseContext)
  const { idToken } = useContext(UserContext)
  const {
    filteredData: { filteredTableData },
    data,
    setData,
  } = useContext(FilterContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])

  const downloadReport = useCallback(async report => {
    setLoading(true)
    try {
      const params = { reportId: report.id, reportType: FEATURE.DISPATCH }
      const result = await apiExternalAdapters.getReportFromReporter(params, idToken)
      const link = document.createElement('a')
      link.href = result
      link.target = '_blank'
      link.click()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [idToken])

  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: t('ID'),
        label: t('ID'),
        customHeaderProps: {
          style: {
            minWidth: '40px',
          },
        },
      },
      {
        accessor: 'workorderdescription',
        Header: t('Work Order Description'),
        label: t('Work Order Description'),
        customHeaderProps: {
          style: {
            minWidth: '40px',
          },
        },
      },
      {
        accessor: 'created',
        Header: t('Dispatch Date'),
        label: t('Dispatch Date'),
        customHeaderProps: {
          style: {
            minWidth: '40px',
          },
        },
      },
      {
        id: 'download',
        Header: t('Download'),
        disableFilters: true,
        customHeaderProps: {
          style: {
            minWidth: '32px',
          },
        },
        Cell: ({ cell: { row } }) => (
          <FlexView alignItems='center' justifyContent='center' width='100%'>
            <Icon name='download' width='24px' height='24px' onClick={() => downloadReport(row.original)} />
          </FlexView>
        ),
      },
    ],
    [downloadReport, t]
  )

  const toggleModal = () => setShowModal((currentState) => !currentState)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(`${stock?.title} - ${t('Dispatch Reports')}`)
    }
  }, [stock, setPageSubtitle, t])

  useEffect(() => {
    async function fetchData(){
      try {
        setLoading(true)
        const data = await apiReports.getDispatchList(stock?.wid, idToken)
        setData(data)
      } catch (error) {
        toast.error(error.message || error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [idToken, setData, stock])

  return (
    <FlexView flex='1' position='relative' alignSelf='stretch'>
      <StockTabs stock={stock} />
      <FlexView margin='16px 16px 8px' flexDirection='row' justifyContent='space-between' alignSelf='stretch'>
        <FilterDisplay options={columns} onTagClick={toggleModal} />
        <FlexView flexDirection='row' alignItems='center' justifyContent='flex-end' flex='1'>
          <Icon
            name='filter'
            width='28px'
            height='28px'
            margin='0px 8px 0px 0px'
            tooltip={t('Filter')}
            onClick={toggleModal}
          />
        </FlexView>
      </FlexView>
      <Card alignSelf='stretch' padding='0px' margin='16px'>
        <TableFilterable columns={columns} data={filteredTableData} />
      </Card>
      <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data} />
      <LoadingOverlay visible={loading} />
    </FlexView>
  )
}

export default DispatchReportsPage
