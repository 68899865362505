import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import apiDynamo from 'api/dynamo'
import apiDocument from 'api/documents'
import apiEndUsers from 'api/end-users'

import { LoadingOverlay, FlexView, Card, Icon } from 'components/common'
import TableFilterable from 'components/common/TableFilterable'
import { DialogModal, Select } from 'components/form'

import StockTabs from 'containers/layout/StockTabs'
import UploadBox from 'containers/tracking-goods/UploadBox'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'
import FilterDisplay from 'containers/common/FilterDisplay'
import { FilterContext } from 'stores/FilterStore'
import FiltersModal from 'containers/common/FiltersModal'
import { loadFile } from 'utils/helpers'

const PageHeader = styled(FlexView)`
   flex-direction: row;
   justify-content: flex-start; 
   align-self: stretch; 
   margin: 10px 0px 0px 15px;
`

const TrackingOfGoodsPage = ({ stockKey }) => {
  const { t } = useTranslation()
  
  const { setPageSubtitle, pageLoading, setPageLoading } = useContext(AppContext)
  const { idToken, currentUser } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])
  
  const [endUser, setEndUser] = useState(undefined)
  
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [showDeleteDialog, toggleDeleteDialog] = useState(false)
  const [ endUsers, setEndUsers ] = useState([])

  const { filteredData: {filteredTableData }, data, setData } = useContext(FilterContext)
  const [showFilterModal, setShowFilterModal] = useState(false)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' + t('Tracking of Goods'))
    }
  }, [stock, setPageSubtitle, t])

  const roles = _.get(currentUser, 'roles.' + process.env.REACT_APP_ID, {})
  
  const inputEndUsers = useMemo(() => _.orderBy(_.uniqBy(endUsers, 'dsc_client'), 'label', 'asc').filter(item => item.wid === stock.wid).map(({ id_global, dsc_client }) => ({
    value: id_global,
    label: dsc_client
  })), [endUsers, stock])
  
  useEffect(() => {
    if(inputEndUsers  && inputEndUsers.length > 0){
      if(!endUser)
        setEndUser(inputEndUsers[0].value)
    }
  }, [endUser, inputEndUsers])

  useEffect(() => {
    async function fetchData(){
        try {
            if(stock){
                setPageLoading(true)
                const response = await apiEndUsers.getAvailableEndUsers(stock?.wid, idToken)
                if(response){
                    setEndUsers(response.endUsers)
                }
            }  
        }
        catch(error){
            toast.error(error.message)
        }
    }
    fetchData() 
  }, [stock]) // eslint-disable-line react-hooks/exhaustive-deps

  const getData = useCallback(async () => {
    if(stock && endUser){
      setPageLoading(true)
      try {
        setPageLoading(true)
        const data = await apiDynamo.getDynamoItems({ 
          tableName: 'TRACKING_OF_GOODS_TABLE', 
          endUser: endUser, 
          wid: stock.wid
        }, idToken)
        setData(_.orderBy(data, ({ uploadDate }) => moment(uploadDate).unix(), 'desc'))
      }
      catch (error) {
        console.log(error)
        toast.error(error.message)
      }
      finally {
        setPageLoading(false)
      }
    }
  }, [endUser, idToken, setData, setPageLoading, stock])

  useEffect(() => {
    if(endUser) {
      getData()
    }

    setPageLoading(false)
  }, [endUser, getData, setPageLoading])
  
  const downloadDocument = useCallback(row => async () => {
    try {
      setPageLoading(true)
      const preSignedUrl = await apiDocument.getPreSignedUrl({ 
        key: 'tracking-of-goods', 
        filename: row?.identifier === 'id' ? row?.id : row?.name, 
        urlAction: 'getObject'
      }, idToken)
      loadFile(preSignedUrl)
      toast.info(t('documents.popupInfo'))
    }
    catch (error) {
      console.log(error)
      toast.error(error.message)
    }
    finally {
      setPageLoading(false)
    }
  }, [idToken, setPageLoading, t])

  const onDeleteClick = useCallback(document => {
      setSelectedDocument(document)
      toggleDeleteDialog(true)
  }, [])
  
  const clearDelete = () => toggleDeleteDialog(false)

  const deleteDocument = useCallback(async () => {
    try {
      setPageLoading(true)
      await apiDocument.deleteFile({ 
        key: 'tracking-of-goods', 
        filename: selectedDocument.name
      }, idToken)

      await apiDynamo.deleteDocumentItem({ 
        tableName: 'TRACKING_OF_GOODS_TABLE', 
        id: selectedDocument.id
      }, idToken)

      await getData()
      toast.success(t('documents.documentDeleted', { name: selectedDocument.name }))
      setSelectedDocument(null)
      toggleDeleteDialog(false)
    }
    catch (error) {
      console.log(error)
      toast.error(error.message)
    }
    finally {
      setPageLoading(false)
    }
  }, [setPageLoading, selectedDocument, idToken, getData, t])

  const columns = useMemo(() => [
    {
      accessor: 'name',
      Header: t('Name'),
      label: t('Name'),
      customHeaderProps: {
        style: {
          minWidth: '400px'
        }
      }
    },
    {
      accessor: 'uploadDate',
      Header: t('Upload Date'),
      label: t('Upload Date'),
      formatter: (props) => props?.uploadDate ? moment(props?.uploadDate).format('MMM D, YYYY, h:mm:ss A') : '',
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '160px'
        }
      }
    },
    {
      accessor: 'description',
      Header: t('Description'),
      label: t('Description'),
      customHeaderProps: {
        style: {
          minWidth: '200px'
        }
      }
    },
    {
      accessor: 'uploadedBy',
      Header: t('Uploaded By'),
      label: t('Uploaded By'),
      customHeaderProps: {
        style: {
          minWidth: '100px'
        }
      }
    },
    {
      accessor: 'id',
      Header: t('Options'),
      Cell: ({ cell: { row } }) => <FlexView flexDirection="row" alignItems="center" justifyContent="center">
        <Icon name="download" width="20px" height="20px" tooltip={t('Download')} margin="0px 8px" onClick={downloadDocument(row.original)} />
        <Icon name="trash" color="error" width="20px" height="20px" tooltip={roles?.hasOwnProperty('READ_AND_WRITE') ?  t('Delete') : t('You need more permissions, ask system administrators')} margin="0px 8px" 
              onClick={() => roles?.hasOwnProperty('READ_AND_WRITE') ? onDeleteClick(row.original) : null } disabled={ !roles.hasOwnProperty('READ_AND_WRITE')} />
      </FlexView>,
      disableFilters: true
    }
  ], [t, downloadDocument, onDeleteClick, roles ])

  const toggleFilterModal = () => setShowFilterModal(currentState => !currentState)

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <PageHeader>
      { 
        endUsers.length > 1 ?
        <Select margin="10px 15px 0px 15px" value={endUser} options={inputEndUsers} onChange={setEndUser} placeHolder={endUser ? endUser : t('Select an option')} /> : null
      }
      <FlexView margin="0px 16px" flexDirection="row"  alignItems="center" justifyContent="space-between" alignSelf="stretch" flex='1'>
        <FilterDisplay options={columns} onTagClick={toggleFilterModal} />
        <Icon name="filter" width="28px" height="28px" tooltip={t('Filter')} onClick={toggleFilterModal}/>
      </FlexView>
    </PageHeader>
    { roles?.hasOwnProperty('READ_AND_WRITE') ? <UploadBox onFileUpload={getData} stock={ stock } endUsers={inputEndUsers} /> : '' }
    <Card alignSelf="stretch" padding="0px" margin="16px">
      <TableFilterable data={filteredTableData} columns={columns} />
    </Card>
    <FiltersModal isOpen={showFilterModal} onOutsideClick={toggleFilterModal} options={columns} data={data}/>
    <DialogModal
      isOpen={showDeleteDialog}
      title={t('Confirm')}
      text={t('documents.confirmDeletion', { name: _.get(selectedDocument, 'name', '') })}
      onConfirm={deleteDocument}
      onCancel={clearDelete}
      danger
    />
    <LoadingOverlay visible={pageLoading} />
  </FlexView>
}

export default TrackingOfGoodsPage