import React, { useMemo, useContext } from 'react'
import { FlexView, Spinner, Card, Icon } from 'components/common'
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from 'recharts'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { StockContext } from 'stores/StockStore'

const COLORS = ['#72aeff', '#b19dff', '#ff9661', '#ff87ab', '#f18ee1', '#ff8a7e', '#3ac5de', '#46c7a8', '#68c17a', '#99b65f']
const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, value
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill="black" textAnchor="middle" dominantBaseline="central" fontSize="12">
      {percent >= 0.1 && <>
       <tspan x={x} y={y}>{value}</tspan>
        <tspan x={x} y={y} dy={14}>{`${(percent * 100).toFixed(0)}%`}</tspan>
      </>}
    </text>
  )
}

const CustomTooltip = (totalPieces, t) => ({ active, payload }) => active ? (
  <Card padding="8px" maxWidth="500px">
    <FlexView margin="4px" fontWeight="bold">{payload[0].name}</FlexView>
    <FlexView margin="4px" color="metalic">{`${payload[0].value} ${t('Pieces')} (${((payload[0].value/totalPieces) * 100).toFixed(2)}%)`}</FlexView>
  </Card>
) : null

const PiecesByMaterialStatusChart = ({ isLoading, modal, onInfoClick, ...rest }) => {
  const { t } = useTranslation()
  const { stockData: { tableData }} = useContext(StockContext)
  const pieces = useMemo(() => _.chain(tableData)
    .groupBy('dscStatusMaterial')
    .map((items, key) => ({
      value: _.sumBy(items, ({ qtdPC }) => Number(qtdPC)),
      label: t(`stock.materialStatus.${key}`)
    }))
    .orderBy('value', 'desc')
    .value()
  , [tableData, t])
  const totalPieces = useMemo(() => _.sumBy(pieces, 'value'), [pieces])

  return <FlexView {...rest}>
    <FlexView flexDirection="row" alignItems="center" justifyContent="space-between" margin="0px 0px 16px" alignSelf="stretch">
      <FlexView fontSize="big" fontWeight="bold">{t('charts.materialStatus.title')}</FlexView>
      {!modal && <Icon name="info" width="24px" height="24px" onClick={onInfoClick} />}
    </FlexView>
    {modal && <FlexView>
      {t('charts.materialStatus.description')}
    </FlexView>}
    {isLoading ?
      <FlexView justifyContent="center" alignItems="center" width="100%" height="100%">
        <Spinner />
      </FlexView>
      :
      <ResponsiveContainer width="99%" height={300}>
        <PieChart>
          <Pie
            data={pieces}
            labelLine={false}
            label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="value"
            nameKey="label">
            {
              pieces.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
          <Tooltip content={CustomTooltip(totalPieces, t)}/>
        </PieChart>
      </ResponsiveContainer>
    }
  </FlexView>
}

export default PiecesByMaterialStatusChart