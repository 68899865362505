import React from 'react'
import { FlexView } from 'components/common'

const PipeProperty = ({ name, value }) => {

  return <FlexView justifyContent="center" margin="8px 0px">
    <FlexView>{value || '-'}</FlexView>
    <FlexView fontSize="small" color="metalic">{name}</FlexView>
  </FlexView>
}

export default PipeProperty