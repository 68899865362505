import axios from 'axios'
import { addLogger } from 'utils/logger'

import { getDefaultHeader, manageError, manageResponse } from './api-util'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

const SHOULD_DECOMPRESS = false
addLogger(instance, false)

export default {
     getWarehouses: async (idToken) => {
        try {
            const response = await instance.get(`warehouses/items`, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
      },
      getWMSWarehouses: async (idToken) => {
          try {
              const response = await instance.get(`warehouses/wms`, getDefaultHeader(idToken))
              return manageResponse(response, SHOULD_DECOMPRESS)
          } catch (error) {
              manageError(error)
          }
      },
      getWarehouseFunctionalities: async (idToken) => {
          try {
              const response = await instance.get(`warehouses/functionalities`, getDefaultHeader(idToken))
              return manageResponse(response)
          } catch (error) {
              manageError(error)
          }
      },
      createWarehouse: async (params, idToken) => {
        try {
            const response = await instance.post('warehouses/creation', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
      },
      updateWarehouse: async (params, idToken) => {
        try {
            const response = await instance.put('warehouses/update', params, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
      },
      deleteWarehouse: async (warehouseId, idToken) => {
        try {
            const response = await instance.delete(`warehouses/deletion?warehouseId=${warehouseId}`, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
      }
}