import React, { useMemo } from 'react'
import { FlexView, Spinner, Icon } from 'components/common'
import { ResponsiveContainer, Tooltip, ComposedChart, XAxis, YAxis, Legend, Bar, Line, CartesianGrid } from 'recharts'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { StockContext } from 'stores/StockStore'
import _ from 'lodash'

const getDateMonthNumber = date => {
  const [month, year] = date.split(' / ')
  return `${year}${Number(month) < 10 ? '0' + month : month}`
}

const StockHistoryChart = ({ isLoading, modal, onInfoClick, ...rest}) => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const { stockData: { monthlyData }} = useContext(StockContext)

  const pieces = useMemo(() => {
    const total = _.chain(monthlyData.res)
      .groupBy('monthYear')
      .map((value, key) => ({
        month: key,
        total: _.sumBy(value, ({ qtdPC }) => Number(qtdPC))
      }))
      .orderBy(({ month }) => getDateMonthNumber(month), 'asc')
      .value()
    const inbound = _.chain(monthlyData.in)
      .groupBy('monthYear')
      .map((value, key) => ({
        month: key,
        inbound: _.sumBy(value, ({ qtdPC }) => Number(qtdPC))
      }))
      .orderBy(({ month }) => getDateMonthNumber(month), 'asc')
      .value()
    const dispatch = _.chain(monthlyData.out)
      .groupBy('monthYear')
      .map((value, key) => ({
        month: key,
        dispatch: _.sumBy(value, ({ qtdPC }) => Number(qtdPC))
      }))
      .orderBy(({ month }) => getDateMonthNumber(month), 'asc')
      .value()
    const merged = []
    const months = total.map(item => item?.month)
    for(const month of months) {
      merged.push({
        month,
        total: total?.find(item => item.month === month)?.total || 0,
        inbound: inbound?.find(item => item.month === month)?.inbound || 0,
        dispatch: dispatch?.find(item => item.month === month)?.dispatch || 0,
      })
    }
    return merged
  }, [monthlyData])

  return <FlexView {...rest}>
    <FlexView flexDirection="row" alignItems="center" justifyContent="space-between" margin="0px 0px 16px" alignSelf="stretch">
      <FlexView fontSize="big" fontWeight="bold">{t('charts.stockHistory.title')}</FlexView>
      {!modal && <Icon name="info" width="24px" height="24px" onClick={onInfoClick} />}
    </FlexView>
    {modal && <FlexView margin="0px 0px 16px">
      {t('charts.stockHistory.description')}
    </FlexView>}
    {isLoading ?
      <FlexView justifyContent="center" alignItems="center" width="100%" height="100%">
        <Spinner />
      </FlexView>
      :
      <ResponsiveContainer width="99%" height={300}>
        <ComposedChart data={pieces} margin={{ top: 8 }}>
          <XAxis dataKey="month" tick={{fontSize: 12}} />
          <YAxis tick={{fontSize: 12}} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Line dataKey="total" name={t('Total in Stock')}  stroke={theme.colors.primary} activeDot={{ r: 2 }} strokeWidth={2}  />
          <Bar dataKey="inbound" name={t('Inbound')} barSize={10} fill={theme.colors.secondary} />
          <Bar dataKey="dispatch" name={t('Dispatch')} barSize={10} fill={theme.colors.tertiary} />
        </ComposedChart>
      </ResponsiveContainer>
    }
  </FlexView>
}

export default StockHistoryChart