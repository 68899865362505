import axios from 'axios'
import { addLogger } from 'utils/logger'

import { getDefaultHeader, manageError, manageResponse } from './api-util'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json', 
        'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
})

const SHOULD_DECOMPRESS = false
addLogger(instance, false)

export default {
    getMaterialRequisitionStatus: async (wid, idToken) => {
        try {
            const response = await instance.get(`reports/material-requisition-status-items?wid=${wid}`, getDefaultHeader(idToken))
           return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getDispatchList: async (wid, idToken) => {
        try {
          const result = await instance.get(`reports/dispatch-items?wid=${wid}`, getDefaultHeader(idToken))
          return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)
        }
    },
    getMaterialRequisitionList: async (wid, idToken) => {
        try {
            const response = await instance.get(`reports/material-requisition-items?wid=${wid}`, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getModifiedProductList: async (wid, idToken) => {
        try {
            const response = await instance.get(`reports/modified-products-items?wid=${wid}`, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getPeriodicalInspectionList: async (wid, idToken) => {
        try {
            const data = await instance.get(`reports/periodical-items?wid=${wid}`, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getPipeAgeReportsList: async (wid, idToken) => {
        try {
            const response = await instance.get(`reports/pipe-age-items?wid=${wid}`, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getPipeAgeViewList: async (wid, idToken) => {
      try {
          const response = await instance.get(`reports/pipe-age-view-items?wid=${wid}`, getDefaultHeader(idToken))
          return manageResponse(response, SHOULD_DECOMPRESS)
      } catch (error) {
          manageError(error)
      }
    },
    getReceiptNotesList: async (params, idToken) => {
        try {
            const endUserParam = params?.endUserId ? `&endUserId=${params?.endUserId}` : ''
            const response = await instance.get(`reports/receipt-notes-items?wid=${params?.wid}` + endUserParam, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getArrivalList: async (wid, idToken) => {
        try {
            const response = await instance.get(`reports/arrival-items?wid=${wid}`, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getRigReturnList: async (wid, idToken) => {
        try {
            const result = await instance.get(`reports/return-items?wid=${wid}`, getDefaultHeader(idToken))
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getBackloadManifestList: async (wid, idToken) => {
        try {
            const result = await instance.get(`reports/backload-manifest-items?wid=${wid}`, getDefaultHeader(idToken))
            return manageResponse(result, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getArchivedReportList: async (params, idToken) => {
        try {
            const data = await instance.get(`reports/archived-items?wid=${params?.wid}&endUserId=${params?.endUserId}`, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }, 
    getDeliveryNotesList: async (params, idToken) => {
        try {
          const response = await instance.get(`reports/delivery-notes-items?wid=${params?.wid}&startDate=${params?.startDate}&endDate=${params?.endDate}`, getDefaultHeader(idToken))
          return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
          manageError(error)
        }
    },
    getDeliveryNotesByPeriod: async (params, idToken) => {
        try {
            const response = await instance.get(`reports/delivery-notes-by-period?wid=${params?.wid}&startDate=${params?.startDate}&endDate=${params?.endDate}&name=${params?.name}`, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getDeliveryNotesInspectionNames: async (wid, idToken) => {
        try {
            const response = await instance.get(`reports/delivery-notes-inspection-names?wid=${wid}`, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    getReceiptInternalReports: async (params, idToken) => {
        try { 
            const response = await instance.get(`reports/receipt-internal-items?initDate=${params?.initDate}&endDate=${params?.endDate}`, getDefaultHeader(idToken))
            return manageResponse(response, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    },
    hideReport: async (params, idToken) => {
        try {
            const data = await instance.post('reports/hide', params, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }, 
    revealReport: async (params, idToken) => {
        try {
            const data = await instance.post('reports/reveal', params, getDefaultHeader(idToken))
            return manageResponse(data, SHOULD_DECOMPRESS)
        } catch (error) {
            manageError(error)
        }
    }
}