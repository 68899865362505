import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'

import apiExternalAdapters from 'api/external-adapters'
import apiReports from 'api/reports'

import { LoadingOverlay, FlexView, Icon, Button } from 'components/common'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { FEATURE } from 'utils/constants'

import FilterDisplay from 'containers/common/FilterDisplay'
import FiltersModal from 'containers/common/FiltersModal'

import { FilterContext } from 'stores/FilterStore'
import TableGrouped from 'components/common/TableGrouped'

const formatReports = (reports) =>
  _.chain(reports)
    .orderBy('inspectionDate', 'desc')
    .orderBy('partNumber', 'asc')
    .value()

const PeriodicalReportsPage = ({ stockKey }) => {
  const { t } = useTranslation()
  const { setPageSubtitle, datasets, formatNumberByCulture } = useContext(AppContext)
  const { endUsers, idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])

  const { filteredData: { filteredTableData }, setData } = useContext(FilterContext)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' + t('Periodical Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const onDownloadClick = useCallback(
    async (inspection) => {
      setLoading(true)
      try {
        setLoading(true)
        const params = { reportId: inspection.id, reportType: FEATURE.PERIODICAL_INSPECTION, isForDashboard: true, partNumber: inspection?.partNumber  }
        const result = await apiExternalAdapters.getReportFromReporter(params, idToken)
        const link = document.createElement('a')
        link.href = result
        link.target = '_blank'
        link.click()
        setLoading(false)
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    },
    [idToken]
  )

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const data = await apiReports.getPeriodicalInspectionList(stock?.wid, idToken)
        setData(formatReports(data))
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [endUsers, datasets, idToken, stock, setData])

  const data = useMemo(() => (
    _.chain(filteredTableData)
      .groupBy((item)=>`${item.id}`)
      .map((value) => ({
        id: value[0].id,
        customerOrder: value[0].customerOrder,
        customerItem: value[0].customerItem,
        salesOrder: value[0].salesOrder,
        salesItem:  value[0].salesItem,
        inspectionDate: value[0].inspectionDate,
        inspectionType: value[0].inspectionType,
        erpReference: value[0].erpReference,
        materialDescription: value[0].materialDescription,
        pipes: _.sumBy(value, ({pipes}) => Number(pipes)),
        children: value || []
      }))
      .orderBy('id', 'desc')
      .value()
  ), [filteredTableData])

  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: t('ID'),
        label: t('ID'),
        customHeaderProps: {
          style: {
            minWidth: '40px',
          },
        },
        Cell: ({ cell: { value, row } }) => (
          <FlexView
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            fontSize="13px"
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 32}px`,
                cursor: row.canExpand ? 'pointer' : 'inherit'
              },
              title: ''
            })}
          >
            {row.canExpand && <Icon name={row.isExpanded ? 'chevron-up' : 'chevron-down'} width="12px" height="12px" margin="0px 4px 0px 8px" color={ row.isExpanded ? 'white' : 'gray'}/>}
            {value === 'null' ? t('Not Found') : value}
          </FlexView>
        ),
        show: true,
        filterModalEnabled: true,
      },
      {
        Header: t('Part Number'),
        accessor: 'partNumber',
        label: t('Part Number'),
        customCellProps: {
          style: {
            textAlign: 'center',
          },
        },
        show: true,
        filterModalEnabled: false,
      },
      {
        Header: t('CUSTOMER PO#'),
        accessor: 'customerOrder',
        label: t('CUSTOMER PO#'),
        customHeaderProps: {
          style: {
            minWidth: '60px',
          },
        },
        customCellProps: {
          style: {
            textAlign: 'center',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        Header: t('CUSTOMER ITEM#'),
        accessor: 'customerItem',
        label: t('CUSTOMER ITEM#'),
        customHeaderProps: {
          style: {
            minWidth: '80px',
          },
        },
        customCellProps: {
          style: {
            textAlign: 'center',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        Header: t('SALES PO#'),
        accessor: 'salesOrder',
        label: t('SALES PO#'),
        customHeaderProps: {
          style: {
            minWidth: '80px',
          },
        },
        customCellProps: {
          style: {
            textAlign: 'center',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        Header: t('SALES ITEM#'),
        accessor: 'salesItem',
        label: t('SALES ITEM#'),
        customHeaderProps: {
          style: {
            minWidth: '60px',
          },
        },
        customCellProps: {
          style: {
            textAlign: 'center',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        id: 'inspectionDate',
        Header: t('Inspection Date'),
        label: t('Inspection Date'),
        accessor: 'inspectionDate',
        customHeaderProps: {
          style: {
            minWidth: '100px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        accessor: 'inspectionType',
        Header: t('Inspection Type'),
        label: t('Inspection Type'),
        customHeaderProps: {
          style: {
            minWidth: '100px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        Header: t('ERP REFERENCE'),
        label: t('ERP REFERENCE'),
        accessor: 'erpReference',
        customHeaderProps: {
          style: {
            minWidth: '60px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        Header: t('Material Description'),
        accessor: 'materialDescription',
        label: t('Material Description'),
        customHeaderProps: {
          style: {
            minWidth: '300px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        Header: `${t('Pipes')}`,
        label: t('Pipes'),
        accessor: 'pipes',
        Cell: (props) => {
          const { pipes } = props.row.original
          return `${formatNumberByCulture(pipes, 0)}`
        },
        customCellProps: {
          style: {
            textAlign: 'center',
            minWidth: '20px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        id: 'download',
        Header: t('Download'),
        disableFilters: true,
        customHeaderProps: {
          style: {
            minWidth: '32px',
          },
        },
        Cell: ({ cell: { row } }) => (
          <Button backgroundColor={!row.canExpand ? '#f5f5f5' : row.isExpanded ? '#667EEA' : 'inherit'} onClick={() => onDownloadClick(row.original)}
          alignItems="center" justifyContent="center" width="100%" margin='0' padding='0'> 
            <Icon name="download" width="24px" height="24px" color={ row.isExpanded ? 'white' : 'gray' } />
          </Button>
        ),
        show: true,
        filterModalEnabled: false,
      },
    ],
    [t, onDownloadClick, formatNumberByCulture]
  )

  const toggleModal = () => setShowModal((currentState) => !currentState)

  return (
    <FlexView flex='1' position='relative' alignSelf='stretch'>
      <StockTabs stock={stock} />
      <FlexView margin='16px 16px 8px' flexDirection='row' justifyContent='space-between' alignSelf='stretch'>
        <FilterDisplay options={columns} onTagClick={toggleModal} />
        <FlexView flexDirection='row' alignItems='center' justifyContent='flex-end' flex='1'>
          <Icon
            name='filter'
            width='28px'
            height='28px'
            margin='0px 8px 0px 0px'
            tooltip={t('Filter')}
            onClick={toggleModal}
          />
        </FlexView>
      </FlexView>
      <TableGrouped columns={columns} data={data} quantityLabel='Items'/>
      <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data} />
      <LoadingOverlay visible={loading} />
    </FlexView>
  )
}

export default PeriodicalReportsPage
