import React, { useContext, useState, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'
import styled from 'styled-components'

import apiReports from 'api/reports'

import { FlexView, Button, Modal, Card, LoadingOverlay, Icon  } from 'components/common'
import { TextArea, Select } from 'components/form'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'

import { REPORT_TYPES, HIDDEN_REPORT_STATUS } from 'utils/constants'
import { WarehouseContext } from 'stores/WarehouseStore'

const Title = styled.div`
    font-family: Roboto;
    font-size: 24px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`
const Subtitle = styled.div`
    font-family: Roboto;
    font-size: 15px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
`

const CustomButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding:10px 0px;
    margin: 8px 8px;
    width: 200px;
    &:active {
        position:relative;
        top:1px;
    }
`
const CloseButton = styled(Button)`
    border-radius:5px;
    border:0px;
    display:inline-block;
    cursor:pointer;
    font-size:15px;
    font-weight: 800;
    padding: 0px 0px;
    margin: 0px 0px;
    background-color: transparent;
    &:active {
        position:relative;
        top:1px;
    }
`
const ReportVisibilityCard = styled(Card)`
width: 50rem;
`

const ReportVisibilityModal = ({ type, isOpen, reportId, onOutsideClick, onCancel, status, stockKey, endUser }) => {
  const { t } = useTranslation()
  
  const { pageLoading, setPageLoading } = useContext(AppContext)
  const { idToken, currentUser } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const [ justification, setJustification] = useState('')
  const [ reportType, setReportType ] = useState('')
  const [ reportIds, setReportIds ] = useState([])
  const [ idReport, setIdReport ] = useState(null)
  const [ isToSearch, setIsToSearch ] = useState(false)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [stockKey, warehouses])

  const title = status === HIDDEN_REPORT_STATUS.ARCHIVED ? t('REVEAL TITLE') : t('ARCHIVE TITLE')
  const subtitle = status === HIDDEN_REPORT_STATUS.ARCHIVED ? t('REVEAL SUBTITLE') : t('ARCHIVE SUBTITLE')
  const textAreaLabel = status === HIDDEN_REPORT_STATUS.ARCHIVED ? t('REVEAL LABEL') : t('ARCHIVE LABEL')
  const buttonLabel = status === HIDDEN_REPORT_STATUS.ARCHIVED ? t('REVEAL') : t('ARCHIVE')
  
  const onJustificationChange = useCallback(event => setJustification(event.target.value), [])

  const formatIds = ids => _.orderBy(ids, 'value', 'asc')

  const formatReportTypes = types => _.orderBy(types, 'label', 'asc')
  const getBackgroundColor = () => status === HIDDEN_REPORT_STATUS.VISIBLE ? 'warning' : 'success'

  const onOutside = () => {
    setJustification('')
    setIdReport(null)
    setReportType('')
    onOutsideClick()
  }

  const onTypeSelect = (type) => {
    setReportType(type)
    setIsToSearch(true)
  }

  const setVisibility = useCallback(async () => {
    const params =  { report_type: type ? type : reportType, id_report: reportId ? reportId : idReport, responsible: currentUser.firstName, justification: justification, wid: stock.wid, endUserId: endUser }
    try {
      setPageLoading(true)
      let result
      if(status){
        result = status === HIDDEN_REPORT_STATUS.VISIBLE 
                        ? await apiReports.hideReport(params, idToken)
                        : await apiReports.revealReport(params, idToken)
      }
      else {
        result = await apiReports.hideReport(params, idToken)
      }
      
      if(result){
        onOutsideClick(true)
        if(!status){
          toast.success(t('Report Archived Successfully!'))
          setReportType('')
          setIdReport(null)
          setIdReport([])
        }
        else {
          toast.success( status === HIDDEN_REPORT_STATUS.VISIBLE 
                         ? t('Report Archived Successfully!') 
                         : t('Report Successfully Revealed!'))
        }
        setJustification('')
        setPageLoading(false)
      }
    }  
    catch(e) {
      setPageLoading(false)
      toast.error( e.message)
    }
  }, [type, reportType, reportId, idReport, currentUser, justification, stock, endUser, setPageLoading, status, idToken, onOutsideClick, t])

  const typeMapped = {
    'BACKLOAD_MANIFEST': {
      listFunction: () => apiReports.getBackloadManifestList(stock?.wid, idToken),
      id: 'rrlid'
    }, 
    'MILL_RECEIPT': {
      listFunction: () => apiReports.getReceiptNotesList(stock?.wid, idToken),
      id: 'beid'
    },
    'RECEIVING_INSP': {
      listFunction: () => apiReports.getArrivalList(stock?.wid, idToken),
      id: 'rlid'
    },
    'RIG_RETURN_INSP':{
      listFunction: () => apiReports.getRigReturnList(stock?.wid, idToken),
      id: 'rrlid'
    },
    'MODIFIED_PRODUCTS':{
      listFunction: () => apiReports.getModifiedProductList(stock?.wid, idToken),
      id: 'mplid'
    },
    'PERIODICAL_INSP':{
      listFunction: () => apiReports.getPeriodicalInspectionList(stock?.wid, idToken),
      id: 'pilid'
    },
    'MATERIAL_REQUISITION':{
      listFunction: () => apiReports.getMaterialRequisitionList(stock?.wid, idToken),
      id: 'id'
    },
    'DELIVERY_NOTES':{
      listFunction: () => apiReports.getDeliveryNotesList(stock?.wid, idToken),
      id: 'id'
    }, 
    'PIPE_AGE_REPORTS': {
      listFunction: () => apiReports.getPipeAgeReportsList(stock?.wid, idToken),
      id: 'id'
    } 
  }

  useEffect(() => {
    try {
      async function fetchData() {
        if(isToSearch && reportType){
          setPageLoading(true)
            const result =  await typeMapped[reportType].listFunction()
            if(result){
              const ids = result.map(item => ({ 
                label: item[typeMapped[reportType].id].toString(), 
                value: item[typeMapped[reportType].id] 
              }))
              setReportIds([])
              setReportIds(formatIds(ids))
              setIsToSearch(false)
              setPageLoading(false)
            }
          }
      }
      fetchData()
    } catch (error) {
      console.log(error)
      toast.error(error.message)
      setPageLoading(false)
      setIsToSearch(false)
    } 
  }, [reportType, idToken, setPageLoading, stock, endUser, typeMapped, isToSearch] )

  const validateWhatDisplay = () => {
    if(reportId){
      return <>
       <FlexView flexDirection='column' alignItems="center" justifyContent="flex-start" width="100%">
        <TextArea label={ textAreaLabel } width="100%" value={ justification } onChange={onJustificationChange} rows="10"></TextArea>
        <FlexView flexDirection='row' alignItems="center" justifyContent="flex-end" width="100%">
          <CustomButton backgroundColor="gray" color="white" onClick={ onOutside }>  
            {t('CANCEL')}  
          </CustomButton>
          <CustomButton  margin="0px 8px" backgroundColor={getBackgroundColor()} color="white" onClick={() => setVisibility() } 
                         disabled={!justification}>  
            { buttonLabel }  
          </CustomButton>
        </FlexView>
      </FlexView>
      </>
    }
    else {
      return  <>
      <FlexView flexDirection='row' width="100%" margin="0px" >
        <FlexView flexDirection='column' margin="0.7rem 0rem 0rem 0rem" alignItems="stretch" justifyContent="space-around">
          <Select width="18rem" placeholder={ reportType ? reportType.label :  t('Select the type of report') } 
                  label={ t('Select the type of report') } options={ formatReportTypes(REPORT_TYPES) } value={ reportType } 
                  onChange={reportType => { onTypeSelect(reportType) }}  margin="0rem 2rem 0rem 0rem"/>
          { 
            reportType
            ? <Select width="18rem" placeholder={ idReport ? idReport.label :  t('Select the report ID') } label={ t('Select the report ID') } 
              options={ reportIds }value={ idReport } onChange={idReport => { setIdReport(idReport) }}  margin="0vh 2vh 0vh 0vh"/>
            : ''
          }     
        </FlexView>
        <TextArea label={ textAreaLabel } width="100%" value={ justification } onChange={onJustificationChange} rows="10"></TextArea>
      </FlexView>
      <FlexView flexDirection='row' alignItems="center" justifyContent="flex-end" width="100%">
        <CustomButton backgroundColor="gray" color="white" onClick={ onOutside }>  
          {t('CANCEL')}  
        </CustomButton>
        <CustomButton  margin="0px 8px" backgroundColor="warning" color="white" onClick={() => setVisibility() } 
                      disabled={!justification || !idReport || !reportType }>  
          { buttonLabel }  
        </CustomButton>
      </FlexView>
      </>
    }
  }

  return (
    <Modal isOpen={isOpen} onOutsideClick={ onOutside } onModalExit={onCancel}>
      <LoadingOverlay visible={pageLoading} />
      <ReportVisibilityCard flexDirection="column" alignItems="center" justifyContent="center">
        <FlexView flexDirection='row' width="100%" justifyContent="space-between" alignItems="flex-start" margin="0px 0px 30px 0px ">
          <FlexView flexDirection='row' justifyContent="flex-start" width="90%">
            <FlexView flexDirection='column' alignItems="flex-start" justifyContent="center" >
                <Title> <i> { title } </i>   </Title>
                <Subtitle> { subtitle } </Subtitle>
            </FlexView>
          </FlexView>
          <FlexView flexDirection='row' justifyContent="flex-end">
            <CloseButton onClick={ onOutside }>
              <Icon name="cross" width="20px" height="20px" color="error"/>
            </CloseButton>
          </FlexView>
        </FlexView>
        { validateWhatDisplay() }
      </ReportVisibilityCard>
    </Modal>
  )
}

export default ReportVisibilityModal