import React, { useContext, useEffect, useState } from 'react'
import * as _ from 'lodash'

import apiWarehouses from 'api/warehouses'

import { LoadingOverlay } from 'components/common'

import { UserContext } from './UserStore'

import { formatWarehouses } from 'utils/warehouse-config/helpers'
import Cookies from 'js-cookie'

const initialState = {
  isLoading: true,
  warehouses: [],
  wmsWarehouses: [],
  allWarehouses: [],
  currentWID: {},
  functionalities: []
}

export const WarehouseContext = React.createContext(initialState)

export const WarehouseProvider = ({ children }) => {

  const { idToken } = useContext(UserContext)
  const [isLoading, setLoading] = useState(initialState.isLoading)
  const [functionalities, setFunctionalities] = useState(initialState.functionalities)
  const [warehouses, setWarehouses] = useState(initialState.warehouses)
  const [wmsWarehouses, setWmsWarehouses] = useState(initialState.wmsWarehouses)
  const [allWarehouses, setAllWarehouses] = useState(initialState.allWarehouses)
  const [currentWID, setCurrentWarehouse] = useState(initialState.currentWID)

  const formatActiveWarehouses = (warehouses) => {
    return warehouses.map(warehouse => (
      {
        ...warehouse,
        isActive: true
      }
    ))
  }

  const formatDifferentWarehouses = (warehouses) => {
    return warehouses.map(warehouse => (
      {
        ...warehouse,
        description: warehouse.name,
        title: warehouse.name,
        length_unit: warehouse.length_unit || 'ft',
        weight_unit: warehouse.weight_unit || 'lb',
        functionalities: [],
        functionalitiesIds: [],
        isActive: false
      })
    )
  }

  useEffect(() => {
    async function fetchWarehouse() {
      try {
        if (!warehouses || _.isEmpty(warehouses)) {
          setLoading(true)
          const response = await apiWarehouses.getWarehouses(idToken)
          if (response){
            setWarehouses(formatWarehouses(response.warehouses))
          }
        }
      }
      catch (error) {
        console.log(error)
      }
      finally {
        setLoading(false)
      }
    }
    fetchWarehouse()
  }, [idToken, setWarehouses, warehouses]) 

  useEffect(() => {
    async function fetchWmsWarehouses() {
      try {
        if(idToken){
          setLoading(true)
          const response = await apiWarehouses.getWMSWarehouses(idToken)
          if (response) {
            setWmsWarehouses(response.warehouses)
          } else {
            setWmsWarehouses([])
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    fetchWmsWarehouses()
  }, [idToken, warehouses])

  useEffect(() => {
    if (warehouses && wmsWarehouses) {
      const different = _.differenceBy(wmsWarehouses, warehouses, 'id')

      const newWarehouses = formatDifferentWarehouses(different)
      const formattedWarehouses = formatActiveWarehouses(warehouses)

      const all = formattedWarehouses.concat(newWarehouses)

      setAllWarehouses(all)
    }
  }, [warehouses, wmsWarehouses])

  useEffect(() => {
    async function fetchFunctionalities() {
      try {
        if(idToken){
          setLoading(true)
          const response = await apiWarehouses.getWarehouseFunctionalities(idToken)
          if(response){
            setFunctionalities(response.functionalities)
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    fetchFunctionalities()
  }, [idToken])

  useEffect(() => {
    allWarehouses.forEach((item) => {
      if (item?.wid) {
        Cookies.set(`_lengthUnit${item.wid}`, item.length_unit)
        Cookies.set(`_weightUnit${item.wid}`, item.weight_unit)
      }
    })
  }, [allWarehouses])

  const renderStore = isLoading ?
    <LoadingOverlay visible={true} />
    : <WarehouseContext.Provider value={{
      warehouses,
      setWarehouses,
      functionalities,
      currentWID,
      setCurrentWarehouse,
      allWarehouses,
    }}>
      {children}
    </WarehouseContext.Provider>

  return renderStore
}
