import React, { useContext, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import { FlexView, Icon } from 'components/common'

import UnitSelect from 'containers/common/UnitSelect'
import PipeTable from 'containers/pipe-data/PipeTable'
import ProductDescriptionCard from 'containers/pipe-data/ProductDescriptionCard'

import { AppContext } from 'stores/AppStore'
import { StockContext } from 'stores/StockStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { exportAsExcelFile } from 'utils/excel'
import Cookies from 'js-cookie'

const PipeDataPage = ({ stockKey }) => {
  
  const { t } = useTranslation()
  const history = useHistory()

  const { setPageSubtitle } = useContext(AppContext)
  const { pipeData, pipeNominalData, loading } = useContext(StockContext)
  const { warehouses } = useContext(WarehouseContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])

  useEffect(() => {
    if (stock) {
      Cookies.set('_currentWarehouse', stockKey)
      setPageSubtitle(stock?.title + ' - ' + t('Pipe Data'))
    }
  }, [stock, setPageSubtitle, t, stockKey])

  useEffect(() => {
    if (!(loading.pipeData || loading.pipeNominalData || pipeData.length || !_.isEmpty(pipeNominalData))) {
      toast.error(t('No Pipe Data Found'))
      history.replace(`/${stock.baseUrl}/service-stock`)
    }
  }, [pipeData, pipeNominalData, loading, history, stock.baseUrl, t])

  const exportPipeDataExcel = async () => {
    exportAsExcelFile(pipeData, `${t('PIPE_DATA_')} ${moment().format('YYYY-MM-DD HH-mm-ss')}`)
  }

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <FlexView margin="16px 16px 0px" flexDirection="row" justifyContent="space-between" alignItems="center" alignSelf="stretch">
      <FlexView flexDirection="row" fontSize="subtitle" color="gray" fontWeight="bold">
        <Icon name="arrow-left" width="24px" height="24px"  margin="0px 16px 0px 0px" onClick={history.goBack} />
        <div style={{ lineHeight: 1 }}>{t('Pipe Data')}</div>
      </FlexView>
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end">
        <UnitSelect />
        <Icon name="xls" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Export to Excel')} style={{ 'cursor': 'pointer' }} onClick={()=>  exportPipeDataExcel()}/>
      </FlexView>
    </FlexView>
    <ProductDescriptionCard />
    <PipeTable />
  </FlexView>
}

export default PipeDataPage