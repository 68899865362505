import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Card, FlexView } from 'components/common'

import theme from 'utils/theme'

const CardContainer = styled(FlexView)`
  flex-direction: row;
  justify-content: space-between; 
  align-items: center; 
  align-self: stretch;
  padding: 0px 16px 0px 16px;
`

const StyledCard = styled(Card)`
  align-self: stretch;
  padding: 0px;
`

const CardHeader = styled(FlexView)`
   border: 1px solid #ddd;
   font-weight: 600;
   font: 'Roboto';
   font-size: ${theme.fontSizes.large};
   padding: 5px 10px; 
   margin: 0px;
   border-radius: 10px 10px 0px 0px;
   align-self: stretch ;
   justify-content: center;
    background: linear-gradient(61deg, rgba(245,245,245,1) 0%, rgba(245,245,245,1) 34%, rgba(245,245,245,1) 69%, rgba(245,245,245,1) 100%);
`

const CardContent = styled(FlexView)`
   font-weight: 400;
   font: 'Roboto';
   font-size: ${theme.fontSizes.large};
   padding: 5px 10px; 
   margin: 0px;
   border-radius: 0px 0px 10px 10px;
   align-self: stretch ;
   align-items: center ;
   justify-content: center;
   border-right: 1px solid #ddd;
   border-left: 1px solid #ddd;
`



const BlockedCardContainer = ({ plannedQty, blockedQty, enteredQty, blockedPercentage }) => {

  const { t } = useTranslation()

  return  <>
  <CardContainer>
      <StyledCard>
          <CardHeader> 
            {t('Total Planned Stock')}
          </CardHeader>
          <CardContent> 
            {plannedQty}
          </CardContent>
      </StyledCard>
      <StyledCard>
          <CardHeader> 
            {t('Total Blocked Stock')}
          </CardHeader>
          <CardContent> 
          { blockedQty < 0
            ? <font color="red" style={{ fontWeight: 600 }}> {blockedQty} </font>
            :<font > {blockedQty} </font>
          } 
          </CardContent>
      </StyledCard>
      <StyledCard>
          <CardHeader> 
              {t('Total Entered Stock')}
          </CardHeader>
          <CardContent> 
              {enteredQty}
          </CardContent>
      </StyledCard>
      <StyledCard>
          <CardHeader> 
              {t('Blocked Percentage')}
          </CardHeader>
          <CardContent > 
              {blockedPercentage}%
          </CardContent>
      </StyledCard>
      </CardContainer>
    </>
}

export default BlockedCardContainer