import React, { useState } from 'react'
import { Card, FlexView, Icon } from 'components/common'
import PipeAgeChart from 'containers/pipe-age/PipeAgeChart'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const StyledCard = styled(Card)`
  ${FlexView}:last-child {
    transition: max-height 0.5s, margin 0.5s;
  }
`

const ChartsCard = ({chartData}) => {
  const { t } = useTranslation()
  const [showCharts, setShowCharts] = useState(false)

  const toggleCharts = () => setShowCharts(currentState => !currentState)

  return <StyledCard alignSelf="stretch" margin="0px 16px 16px" padding="16px">
    <FlexView flexDirection="row" alignItems="center" justifyContent="space-between" alignSelf="stretch" onClick={toggleCharts} style={{ cursor: 'pointer' }}>
      <FlexView fontSize="big" fontWeight="bold" style={{ cursor: 'pointer' }}>{t('Charts')}</FlexView>
      <Icon name={showCharts ? 'chevron-up' : 'chevron-down'} width="24px" height="24px"></Icon>
    </FlexView>
    <FlexView flexDirection="row" alignItems="stretch" alignSelf="stretch" margin={`${showCharts ? '24px' : '0px'} 0px 0px`} maxHeight={showCharts ? '500px' : '0px'} 
      style={{ overflow: 'hidden' }}>
      <PipeAgeChart chartData={chartData} flex="2" margin="0px 0px 0px 16px" />
    </FlexView>
  </StyledCard>
}

export default ChartsCard